import style from "./Graph.module.css";
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Area,
    AreaChart
} from 'recharts';

// GRAPH COMPONENT
const Graph = () => {

    // DATA THAT WILL BE REPRESENTED IN GRAPH
    const data = [
        { name: 'Jan', value: 10000 },
        { name: 'Feb', value: 20000 },
        { name: 'Mar', value: 2000 },
        { name: 'Apr', value: 16000 },
        { name: 'May', value: 15000 },
        { name: 'Jun', value: 25000 },
        { name: 'Jul', value: 30000 },
        { name: 'Aug', value: 500 },
        { name: 'Sep', value: 12000 },
        { name: 'Oct', value: 13000 },
        { name: 'Nov', value: 13000 },
        { name: 'Dec', value: 15000 }
    ];

    // LAYOUT
    return (
        <ResponsiveContainer width="100%" height={312}>
            <AreaChart
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="rgba(69, 208, 238, 0.20)" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="rgba(69, 208, 238, 0.00)" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <CartesianGrid stroke="#F4F4F4" strokeArray="3 3" />
                <XAxis dataKey="name" stroke="#18181A" />
                <YAxis stroke="#18181A" />
                <Tooltip />
                <Area
                    type="linear"
                    dataKey="value"
                    stroke="#45D0EE"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                    activeDot={{ r: 8 }}
                    dot={false}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

// EXPORT 
export default Graph;