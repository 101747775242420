import { notificationsAPI, usersAPI } from "../api/api";

// ACTION TYPES
const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

// INITIAL STATE
const initialState = {
    notifications: [],
};

// NOTIFICATIONS REDUCER
export const notificationsReducer = (state = initialState, action) => {
    switch(action.type) {
        case CREATE_NOTIFICATION:
            return { ...state, notifications: [...state.notifications, action.notification]};
        case UPDATE_NOTIFICATION:
            return { ...state, notifications: state.notifications.map(notification => {
                if(notification.id === action.notification.id) {
                    return action.notification;
                }
                return notification;
            })};
        default:
            return state;
    } 
};

// ACTION CREATORS
export const createNotificationActionCreator = (notification) => ({type: CREATE_NOTIFICATION, notification});
export const updateNotificationActionCreator = (notification) => ({type: UPDATE_NOTIFICATION, notification});

// THUNKS
export const createNotification = (deadline, user, candidate, test, company) => dispatch => {
    notificationsAPI.createNotification(deadline, user, candidate, test, company)
    .then(response => {
        if(response.status === 201) {
            dispatch(createNotificationActionCreator(response.data));
        }
    });
};
export const updateNotification = (id, message, is_viewed) => dispatch => {
    notificationsAPI.updateNotification(id, message, is_viewed)
    .then(response => {
        if(response.status === 200) {
            dispatch(updateNotificationActionCreator(response.data));
        }
    })
};
export const assignTests = (accessed_tests, id, deadline, attempts) => dispatch => {
    usersAPI.assignTests(accessed_tests, id)
    .then(response => {
        if(response.status === 200) {
            accessed_tests.forEach(test => {
                createNotification("Вам назначили тест!", deadline, attempts, false, id, test);
            })
        }
    })
};