import { NavLink } from "react-router-dom";
import style from "./NavigationBarItem.module.css";
import noImage from "../../../../assets/images/no-image.png";

// NAVIGATION ITEM COMPONENT
const NavigationBarItem = ({ icon, text, isActive, path, image, state }) => {

    // LAYOUT
    return (
        <div className={`${style.navigationBarItem} ${isActive ? style.navigationBarItemActive : ""}`}>
            <NavLink to={path} state={state}>
                <div className={`flex ${style.navigationBarItemFlex}`}>
                    {
                        icon && <i className={`${icon} ${style.navigationBarItemIcon}`} />
                    }
                    {
                        image && <img src={image || noImage} alt="logo" className={style.navigationBarItemImage} />
                    }
                    <h3 className={style.navigationBarItemText}>{text}</h3>
                </div>
            </NavLink>
        </div>
    );
};

// EXPORT
export default NavigationBarItem;