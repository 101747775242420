import { permissionsAPI } from "../../api/api";

// ACTION TYPES
export const ADD_PERMISSION = "ADD_PERMISSION";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const UPDATE_PERMISSION = "UPDATE_PERMISSION";
export const DELETE_PERMISSION = "DELETE_PERMISSION";

// INITIAL STATE
const initialState = {
    permissions: []
};

// REDUCER
export const permissionsReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_PERMISSION:
            return {...state, permissions: [...state.permissions, action.permission]};
        case SET_PERMISSIONS:
            return {...state, permissions: action.permissions};
        case UPDATE_PERMISSION:
            state.permissions.find(permission => permission.id === action.permission.id).title = action.permission.title;
            return {...state, permissions: [...state.permissions]};
        case DELETE_PERMISSION:
            const removedPermission = state.permissions.find(permission => permission.id === action.permission.id);
            const indexOfRemovedPermission = state.permissions.indexOf(removedPermission);
            return {...state, permissions: state.permissions.splice(indexOfRemovedPermission, 1)};
        default:
            return state;
    }
};

// ACTION CREATORS
export const addPermissionActionCreator = (permission) => ({type: ADD_PERMISSION, permission});
export const setPermissionsActionCreator = (permissions) => ({type: SET_PERMISSIONS, permissions});
export const updatePermissionActionCreator = (permission) => ({type: UPDATE_PERMISSION, permission});
export const deletePermissionActionCreator = (permission) => ({type: DELETE_PERMISSION, permission});

// THUNKS
export const addPermission = (title) => dispatch => {
    permissionsAPI.addPermission(title)
    .then(response => {
        dispatch(addPermissionActionCreator(response.data));
    });
};
export const getPermissions = () => dispatch => {
    permissionsAPI.getPermissions()
    .then(response => {
        dispatch(setPermissionsActionCreator(response.data));
    });
}
export const updatePermission = (id, title) => dispatch => {
    permissionsAPI.updatePermission(id, title)
    .then(response => {
        dispatch(updatePermissionActionCreator(response.data));
    });
}
export const deletePermission = (id) => dispatch => {
    permissionsAPI.deletePermission(id)
    .then(response => {
        dispatch(deletePermissionActionCreator(response.data));
    });
}