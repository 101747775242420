import { connect } from "react-redux";
import { clearSelectedTests, removeSelectedTest } from "../../../redux/testsReducer";
import AssignTests from "./AssignTests";

// MAP STATE TO PROPS
const mapStateToProps = (state) => {
    return {
        selectedTests: state.tests.selectedTests,
        tests: state.tests.tests,
    };
};

// CONTAINER COMPONENT FOR ASSIGN TESTS COMPONENT
export const AssignTestsContainer = connect(mapStateToProps, {clearSelectedTests, removeSelectedTest})(AssignTests);