import Select from "react-select";

// SELECT COMPONENT
const SelectComponent = ({value, onChange, options}) => {

    // LAYOUT
    return (
        <Select
            value={value}
            onChange={onChange}
            options={options}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    borderColor: "#9F9F9F",
                    borderRadius: "10px",
                    backgroundColor: "#FFF",
                    boxShadow: state.isFocused ? null : null,
                    width: "100%",
                    height: "40px",
                    "&:hover": {
                        borderColor: '#9F9F9F'
                    },
                }),
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected && "#FA8814",
                    "&:hover": {
                        backgroundColor: '#FFF1E3'
                    },
                }),
                menu: (provided, state) => ({
                    ...provided,
                    width: "100%",
                }),
            }}
        />
    );
};

// EXPORT
export default SelectComponent;