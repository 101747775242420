export const registrationValidate = (values) => {
    
    const errors = {};

    // IF @ IS THE FIRST LETTER IN MAIL
    if(values?.email?.[0] === "@") {
        errors.email = "Неправильная почта!";
    };

    // IF NO MAIL WAS INSERTED
    if(!values.email) {
        errors.email = "Требуется почта!"
    };

    // IF LENGTH OF PASSWORD IS LESS THAN 8
    if(values?.password?.length < 8) {
        errors.password = "Пароль должен состоять из минимум 8 символов";
    };

    // IF PASSWORD WAS NOT ENTERED
    if(!values.password) {
        errors.password = "Введите пароль!";
    };

    // RETURNING ERRORS
    return errors;

};