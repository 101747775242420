export function convertDateToHyphen(date) {

    // SPLITTING DATE TO GET DAY, MONTH AND YEAR SEPARATELY
    const dateArray = date.split(".");

    // GETTING YEAR, MONTH AND DAY
    const year = dateArray[2];
    const month = dateArray[1];
    const day = dateArray[0];

    // RETURNING FORMAT SEPARATED WITH HYPHENS
    return `${year}-${month}-${day}`;
};