import HeaderLogo from "../HeaderLogo/HeaderLogo";
import logo from "../../../assets/images/logo.png";
import style from "./Footer.module.css";

// FOOTER COMPONENT
const Footer = () => {

    // LAYOUT
    return (
        <div className={`flex ${style.flex_footer}`}>
            <div className={`flex ${style.flex_logo}`}>
                <HeaderLogo source={logo} />
                <p className={style.text_footer}>© no hunt 2023</p>
            </div>
        </div>
    );
};

// EXPORTING FOOTER
export default Footer;