
// ROLES
const roles = [
    {
        value: "admin",
        label: "Админ",
    },
    {
        value: "moderator",
        label: "Рекрутер",
    },
    {
        value: "user",
        label: "Сотрудник",
    },
];

// EXPORTING ROLES
export default roles;