import style from "./DropDown.module.css";

// LAYOUT
const DropDown = ({text, options}) => {

    return (
        <div className={style.dropdown}>
            <p>{text}</p>
            <i className={`icon-arrow-down ${style.downArrow}`} />
        </div>
    );
};

// EXPORT DROPDOWN
export default DropDown;