import { resultsAPI } from "../api/api";
import { errorToast, successToast } from "../utils/toasts/toasts";
import { updateNotification } from "./notificationsReducer";

// ACTION TYPES
const CREATE_RESULT = "CREATE_RESULT";
const UPDATE_RESULT = "UPDATE_RESULT";

// INITIAL STATE
const initialState = {
    result: {},
};

// RESULTS REDUCER
export const resultsReducer = (state = initialState, action) => {
    switch(action.type) {
        case CREATE_RESULT:
            return { ...state, result: action.result };
        case UPDATE_RESULT:
            return { ...state, result: action.result};
        default:
            return state;
    };
};

// ACTION CREATORS
export const createResultActionCreator = (result) => ({type: CREATE_RESULT, result});
export const updateResultActionCreator = (result) => ({type: UPDATE_RESULT, result});

// THUNKS
export const createResult = (test) => dispatch => {
    resultsAPI.createResult(test)
    .then(response => {
        if(response.status === 201) {
            dispatch(createResultActionCreator(response.data));
        }
    })
};
export const createCandidateResult = (test) => dispatch => {
    resultsAPI.createCandidateResult(test)
    .then(response => {
        if(response.status === 201) {
            dispatch(createResultActionCreator(response.data));
        }
    })
    .catch(error => {
        errorToast("Что-то пошло не так. Попробуйте обратиться в службу поддержки");
    })
};
export const updateResult = (id) => dispatch => {
    return resultsAPI.updateResult(id)
    .then(response => {
        if(response.status === 200) {
            successToast("Вы успешно сдали ответы");
        }
    })
    .catch(error => {
        errorToast(error.message);
    })
};