import axios from "axios";
import instance from "../utils/token/axiosInterceptor";

// AUTH API
export const authAPI = {
    login(email, password) {
        return instance.post("main/jwt/token/", {
            email,
            password,
        });
    },
    register(name, email, password, phone, role, companyId, user_groups) {
        return instance.post("main/users/", {
            name,
            email,
            password,
            phone,
            role,
            company: companyId,
            user_groups,
        });
    },
    refreshToken(token) {
        return instance.post("main/jwt/token/refresh/", {refresh: token});
    },
    verifyToken(token) {
        return instance.post("main/jwt/token/verify/", {token});
    },
    resetPassword(userId, password) {
        return instance.patch(`main/users/${userId}/`, {password});
    }
};

// PERMISSIONS API
export const permissionsAPI = {
    addPermission(title) {
        return instance.post("permissions", {title});
    },
    getPermissions() {
        return instance.get("permissions");
    },
    updatePermission(id, title) {
        return instance.patch(`permissions/${id}`, {title});
    },
    deletePermission(id) {
        return instance.delete(`permissions/${id}`);
    },
};

// ROLES API
export const rolesAPI = {
    createRole(title, permissions) {
        return instance.post("roles", {title, permissions}); // permissions = [id1, id2, ... , idn]
    },
    getRoles() {
        return instance.get("roles");
    },
    updateRole(title, permissions, id) {
        return instance.patch(`roles/${id}`, {title, permissions});
    },
    deleteRole(id) {
        return instance.delete(`roles/${id}`);
    },
};

// USERS API
export const usersAPI = {
    createUser(name, user_groups, email, password, company) {
        return instance.post("main/users/", {name, user_groups, email, password, company});
    },
    getUsers() {
        return instance.get("main/users/");
    },
    getUser(id) {
        return instance.get(`main/users/${id}/`);
    },
    updateUser(formData, id) {
        return instance.patch(`main/users/${id}/`, formData);
    },
    assignTests(accessed_tests, id) {
        return instance.patch(`main/users/${id}/`, {accessed_tests});
    },
    deleteUser(userId) {
        return instance.delete(`main/users/${userId}/`);
    },
    getUserOfGroup(usergroupId) {
        return instance.get(`/main/users/of_group/${usergroupId}`);
    },
    blockUser(id) {
        return instance.patch(`main/users/${id}/`, {is_active: false});
    },
    unblockUser(id) {
        return instance.patch(`main/users/${id}/`, {is_active: true});
    },
};

// USER GROUPS API
export const userGroupsAPI = {
    createUserGroup(name, description, company) {
        return instance.post("main/usergroups/", {name, description, company});
    },
    updateUserGroup(name, description, accessed_tests, id) {
        return instance.patch(`main/usergroups/${id}/`, {name, description, accessed_tests});
    },
    getUserGroup(id) {
        return instance.get(`main/usergroups/${id}/`);
    },
    getUserGroups() {
        return instance.get("main/usergroups/");
    },
    getUserGroupsByCompany(companyId) {
        return instance.get(`main/user_groups/of_company/${companyId}`);
    },
    getUsersByGroup(userGroupId) {
        return instance.get(`main/users/of_group/${userGroupId}`);
    },
};

// TESTS API
export const testsAPI = {
    getTests() {
        return instance.get("main/test_and_questions/");
    },
    getTest(id) {
        return instance.get(`main/test_and_questions/${id}/`);
    },
    getTestCandidate(id) {
        const token = localStorage.getItem("candidateToken");
        return axios.get(`http://127.0.0.1:8000/main/test_and_questions/${id}/`, {headers: {"Authorization": `DMT ${token}`}});
    },
    createTest(formData) {
        return instance.post("main/tests/", formData);
    },
    updateTest(formData, id) {
        return instance.patch(`main/tests/${id}/`, formData);
    },
    deleteTest(id) {
        return instance.delete(`main/tests/${id}/`);
    },
    getQuestions() {
        return instance.get("main/questions/");
    },
    createQuestion(type, name, text, description, test) {
        return instance.post("main/questions/", {type, name, text, description, test, cost: 1});
    },
    updateQuestion(name, text, description, test, id) {
        return instance.patch(`main/questions/${id}/`, {name, text, description, test});
    },
    getChoices() {
        return instance.get("main/choices/");
    },
    createChoice(text, is_correct, question) {
        return instance.post("main/choices/", {text, is_correct, question});
    },
    updateChoice(text, is_correct, id) {
        return instance.patch(`main/choices/${id}/`, {text, is_correct});
    },
    sendAnswer(question, answers, resultId) {
        if(localStorage.getItem("candidateToken")) {
            const token = localStorage.getItem("candidateToken");
            return axios.post("http://127.0.0.1:8000/main/answers/", 
            {question, answers, result: resultId}, 
            {headers: {"Authorization": `DMT ${token}`}})
        } else {
            return instance.post("main/answers/", {question, answers, result: resultId});
        }
    },
    updateAnswer(question, answers, id) {
        return instance.patch(`main/answers/${id}/`, {question, answers});
    },
};

// RESULTS API
export const resultsAPI = {
    createResult(test) {
        return instance.post("main/results/", {test});
    },
    createCandidateResult(test) {
        const token = localStorage.getItem("candidateToken");
        return axios.post("http://127.0.0.1:8000/main/results/", {test}, {headers: {"Authorization": `DMT ${token}`}});
    },
    updateResult(id) {
        const token = localStorage.getItem("candidateToken");
        if(token) {
            return axios.patch(`http://127.0.0.1:8000/main/results/${id}/`, {}, {headers: {"Authorization": `DMT ${token}`}});
        } else {
            return instance.patch(`main/results/${id}/`);
        }
    },
};

// NOTIFICATIONS API
export const notificationsAPI = {
    createNotification(deadline, user, candidate, test, company) {
        return instance.post("main/notifications/", {deadline, user, candidate, test, company});
    },
    updateNotification(id, message, is_viewed) {
        return instance.patch(`main/notifications/${id}/`, {message, is_viewed});
    },
};

// COMPANIES API
export const companiesAPI = {
    getCompanies() {
        return instance.get("main/companies/");
    },
    getCompany(companyId) {
        return instance.get(`main/companies/${companyId}/`);
    },
    createCompany(formData) {
        return instance.post("main/companies/", formData);
    },
    updateCompany(formData, companyId) {
        return instance.patch(`main/companies/${companyId}/`, formData);
    },
    deleteCompany(companyId) {
        return instance.delete(`main/companies/${companyId}/`);
    },
    blockCompany(inactive_till, companyId) {
        return instance.patch(`main/companies/${companyId}/`, {inactive_till, active: false});
    },
    unblockCompany(active_till, companyId) {
        return instance.patch(`main/companies/${companyId}/`, {active_till, active: true});
    },
};

// CANDIDATES API
export const candidatesAPI = {
    createCandidate(name, email, company, accessed_tests) {
        return instance.post("main/candidates/", {name, email, company, accessed_tests});
    },
    updateCandidate(candidateId, accessed_tests) {
        return instance.patch(`main/candidates/${candidateId}/`, {accessed_tests});
    },
};