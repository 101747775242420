export function formatDuration(minutes) {
    // Create a date object at the starting time
    let date = new Date(0, 0, 0);
    
    // Set the minutes
    date.setMinutes(minutes);
    
    // Format the time as a string in hh:mm:ss format
    let timeString = date.toTimeString().split(' ')[0];
    
    return timeString;
}