import { NavLink } from "react-router-dom";
import style from "./BreadCrumbs.module.css";
import noImage from "../../../assets/images/no-image.png";

// BREADCRUMBS COMPONENT
const BreadCrumbs = ({links}) => {

    // LAYOUT
    return (
    <div className={`flex ${style.breadcrumbs}`}>
        <NavLink to="/">
            <i className={`icon-home-2 ${style.icon}`} />
        </NavLink>
        {links.length && <i className={`icon-octicon_chevron-right-16 ${style.chevron}`} />}
        {links.length && links.map((link, index) => (
            <NavLink key={index} to={link.path} state={link?.state} className={style.breadcrumbsLink}>
                {
                    link?.icon && <i className={`${link.icon} ${link.current ? style.breadcrumbsLinkActive : ""} ${style.icon}`} />
                }
                {
                    link.hasOwnProperty("image") 
                    && <img id="crumbsImage" src={link.image || noImage} alt="logo" className={style.breadcrumbsImage} />
                }
                <p className={`${style.breadcrumbsLinkText} ${link.current ? style.breadcrumbsLinkActive : ""}`}>{link.text}</p>
                {
                    (index !== links.length - 1) && (
                        <i className={`icon-octicon_chevron-right-16 ${style.chevron} ${style.iconChevron}`} />
                    ) 
                }
            </NavLink>
        ))}
    </div>
    )
};

// EXPORT
export default BreadCrumbs;