import style from "./ChoiceTab.module.css";

// CHOICE TAB COMPONENT
const ChoiceTab = ({isActive, text, onClick}) => {

    // LAYOUT
    return (
        <div className={`${style.choiceTab} ${isActive ? style.choiceTabActive : ""}`} onClick={onClick}>
            <p className={style.choiceTabText}>{text}</p>
        </div>
    );
};

// EXPORT
export default ChoiceTab;