import { authReducer } from "./authReducer";
import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import { permissionsReducer } from "./inDoubt/permissionsReducer";
import { rolesReducer } from "./inDoubt/rolesReducer";
import { testsReducer } from "./testsReducer";
import { answersReducer } from "./answersReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { usersReducer } from "./usersReducer";
import { companiesReducer } from "./companiesReducer";
import { userGroupsReducer } from "./userGroupsReducer";
import { candidatesReducer } from "./candidatesReducer";
import { resultsReducer } from "./resultsReducer";
import { preloaderReducer } from "./preloaderReducer";

// PERSIST CONFIGURATION
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'], // only auth will be persisted
  };

// REDUCERS
const reducers = combineReducers({
    auth: authReducer,
    permissions: permissionsReducer,
    roles: rolesReducer,
    tests: testsReducer,
    answers: answersReducer,
    users: usersReducer,
    companies: companiesReducer,
    userGroups: userGroupsReducer,
    candidates: candidatesReducer,
    results: resultsReducer,
    preloader: preloaderReducer, 
});

// PERSISTED REDUCER
const persistedReducer = persistReducer(persistConfig, reducers);

// MAIN STORE
const store = createStore(persistedReducer, applyMiddleware(thunkMiddleware));
const persistor = persistStore(store);

// EXPORTING STORE
export { store, persistor };