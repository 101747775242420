import { useEffect, useState } from "react";
import Pagination from "../Pagination/Pagination";
import style from "./Table.module.css";
import TableHeader from "./TableHeader/TableHeader";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useNavigate } from "react-router";

// TABLE COMPONENT
const TableComponent = ({ columns, rows, entity, headerText, headerButtonText, page, state }) => {

    const navigate = useNavigate();

    const pageSize = 10;
    const totalPages = Math.ceil(rows.length / pageSize);
    const [allData, setAllData] = useState(rows);
    const [pageData, setPageData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    // FUNCTION TO NAVIGATE TO VIEW PAGE
    const navigateToView = (id) => () => {
        if(page) {
            navigate(`${page}/${id}`, {state: {
                id,
                state,
            }});
        } else {
            navigate(`/${entity}/${id}`, {state: {
                id,
            }})
        }
    };

    useEffect(() => {
        setAllData(rows);
    }, [rows]);

    useEffect(() => {
        const start = (currentPage - 1) * pageSize;
        const end = start + pageSize;
        setPageData(allData.slice(start, end));
    }, [allData, currentPage]);

    // LAYOUT
    return (
        <div className={`flex__column ${style.table}`}>
            <div className={`flex__column ${style.tableMain}`}>
                <TableHeader text={headerText} buttonText={headerButtonText} entity={entity} page={page} state={state} />
                <TableContainer component={Paper} sx={{
                    width: "100%",
                    boxShadow: "none",
                }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map((column, index) => (
                                        <TableCell key={index} sx={{
                                            color: "#9F9F9F",
                                            fontSize: "14px",
                                            fontFamily: "Raleway",
                                        }}>{column.includes("icon") ? <i className={column} /> : column}</TableCell>
                                    )
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                pageData?.length
                                    ? pageData.map((row, index) => (
                                        <TableRow key={index}>
                                            {
                                                Object.keys(row).map((key, index) => {
                                                    if (key === "id") return;
                                                    return (
                                                        <TableCell
                                                            key={index}
                                                            sx={{
                                                                fontFamily: "Raleway",
                                                                fontWeight: "500",
                                                                fontSize: "16px",
                                                                color: "#18181A",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={navigateToView(row?.id)}
                                                        >
                                                            {
                                                                index === Object.keys(row).length - 1
                                                                    ? <i className={row[key]} />
                                                                    : row[key]
                                                            }
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    ))
                                    : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </div>
    );
};

// EXPORT 
export default TableComponent;