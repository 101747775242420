import ButtonNew from "../../components/UI/ButtonNew/ButtonNew";

export const renderPageNumbers = (totalPages, currentPage, onPageChange) => {

    const maxVisiblePages = 7; // adjust as needed
    const buffer = 2; // pages around current

    let pages = [];
    for (let i = 1; i <= totalPages; i++) {

        if (i <= 2 || i > totalPages - 2 || (i >= currentPage - buffer && i <= currentPage + buffer)) {
            pages.push(
                <ButtonNew
                    key={i}
                    className={currentPage === i ? 'button_gradient_small' : 'button_disabled_small'}
                    onClick={() => onPageChange(i)}
                    text={i}
                />
            );
        } else if (i === 3 || i === totalPages - 3) {
            // Add ellipses but prevent duplicates
            if (pages[pages.length - 1] !== '...') {
                pages.push(<ButtonNew key={i} className="button_disabled_small" text="..." />);
            }
        }
    }
    return pages;
}