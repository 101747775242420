import { useDispatch, useSelector } from "react-redux";
import style from "./TestProgress.module.css";
import { sendAnswer } from "../../../redux/answersReducer";
import { updateResult } from "../../../redux/resultsReducer";
import { useNavigate } from "react-router-dom";
import ButtonNew from "../../../components/UI/ButtonNew/ButtonNew";

// TEST PROGRESS COMPONENT
const TestProgress = ({ questionsTotal }) => {

    // USE SELECTOR
    const answersState = useSelector(state => state.answers);
    const resultsState = useSelector(state => state.results);

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE DISPATCH
    const dispatch = useDispatch();

    // SUBMIT TEST CALLBACK
    const submitTest = function () {
        const answerPromises = answersState.answers.map(answer =>
            dispatch(sendAnswer(answer.question, answer.answers, false, resultsState.result.id, null))
        );
        Promise.all(answerPromises)
            .then(() => {
                dispatch(updateResult(resultsState.result.id))
                .then(response => {
                    // navigate("/test_success");
                })
            })
    };

    // LAYOUT
    return (
        <div className={`flex__column ${style.progress}`}>
            <h3 className={style.progressHeading}>Прогресс</h3>
            <div className={`flex ${style.progressCheckboxes}`}>
                {Array.from({ length: questionsTotal }).map((_, index) => {
                    return (
                        <i
                            key={index}
                            className={`icon-checkbox-${!answersState.answers
                                ?.find(answer => answer.index === index + 1)?.answers?.length
                                ? "disabled"
                                : "active"} ${style.checkbox}`}
                        />
                    )
                })}
            </div>
            <ButtonNew
                onClick={submitTest}
                type="button"
                text="Закончить тест"
                className="button_gradient"
                icon="icon-clipboard-tick"
            />
        </div>
    );
};

// EXPORT
export default TestProgress;