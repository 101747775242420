import { useState } from "react";
import { renderPageNumbers } from "../../../utils/pagination/renderPageNumbers";
import ButtonNew from "../../UI/ButtonNew/ButtonNew";
import style from "./Pagination.module.css";

// PAGINATION COMPONENT
const Pagination = ({totalPages, currentPage, setCurrentPage}) => {

    // ON PAGE CHANGE
    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    // FUNCTIONS TO HANDLE CLICKS ON PREVIOUS AND NEXT BUTTONS
    const handlePrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    }
    
    const handleNext = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    }

    // LAYOUT
    return (
        <div className={`flex ${style.pagination}`}>
            <ButtonNew 
                disabled={currentPage === 1} 
                className="button_outline_small" 
                icon="icon-octicon_chevron-right-16"
                onClick={handlePrevious}
                iconDirection="left" 
            />
            {
                renderPageNumbers(totalPages, currentPage, onPageChange)
            }
            <ButtonNew 
                disabled={currentPage === totalPages} 
                className="button_outline_small" 
                icon="icon-octicon_chevron-right-16" 
                onClick={handleNext}
                iconDirection="right"
            />
        </div>
    );
};

// EXPORT 
export default Pagination;