import { useState } from "react";
import style from "./Checkbox.module.css";

// CHECKBOX COMPONENT
const Checkbox = ({onCheck, onUncheck, isChecked, className}) => {

    // USE STATE
    // const [checked, setChecked] = useState(isChecked);

    // ON CLICK HANDLER
    const onCheckboxClick = function() {
        if(isChecked) {
            onUncheck();
        } else {
            onCheck();
        }
        // setChecked(previous => !previous);
    };

    // LAYOUT
    return (
        <div onClick={onCheckboxClick}>
            {
                isChecked 
                ? <i className={`icon-tick-square ${style.checkbox} ${style[className]}`} />
                : <i className={`icon-checkbox-disabled ${style.checkbox}`} />
            }
        </div>
    );
};

// EXPORT
export default Checkbox;