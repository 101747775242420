import style from "./ProfileDropdown.module.css";
import avatar from "../../../assets/images/avatar.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { startLoadingActionCreator } from "../../../redux/preloaderReducer";
import { getCompany } from "../../../redux/companiesReducer";
import { logout } from "../../../redux/authReducer";
import Preloader from "../Preloader/Preloader";

// PROFILE DROPDOWN COMPONENT
const ProfileDropdown = ({ name }) => {

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE STATE
    // STATE FOR DROPDOWN MENU OPEN/CLOSE
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // USE SELECTOR
    const { role, companyId, profileData } = useSelector(state => state.auth);
    const { isLoading } = useSelector(state => state.preloader);
    const { currentCompany } = useSelector(state => state.companies);

    // USE DISPATCH
    const dispatch = useDispatch();

    // USE EFFECT
    // GET THE COMPANY OF THE USER
    useEffect(() => {
        if (companyId) {
            dispatch(startLoadingActionCreator());
            dispatch(getCompany(companyId));
        }
    }, [companyId]);

    // CHECKING IF IT IS USER
    const isUser = role === "user";

    // CHECKING IF IT IS SUPERUSER
    const isSuperUser = role === "super";

    // PATHS TO PAGES FROM DROPDOWN MENU
    const profilePath = isUser ? "/" : `/companies/${companyId}/staff/${profileData.id}`;
    const profileState = isUser ? {} : { id: profileData.id, company: currentCompany };

    // CALLBACKS
    // FUNCTION TO HANDLE DROPDOWN'S STATE
    const handleDropdown = function(event) {

        // STOPPING EVENT BUBBLING
        event.stopPropagation();

        // SWITCHING STATE OF DROPDOWN
        setDropdownOpen(previous => !previous);
    };
    // LOGOUT FUNCTION
    const handleLogout = function() {
        
        // DISPATCHING LOGOUT STATE
        dispatch(logout());

        // NAVIGATING TO LOGIN PAGE
        navigate("/login");
    };

    if (isLoading) return <Preloader />;

    // LAYOUT
    return (
        <div className={`flex ${style.profileDropdown} ${dropdownOpen ? style.profileDropdownActive : ""}`} onClick={handleDropdown}>
            <img className={`${style.iconProfileCircle_large}`} src={avatar} />
            <p className={style.profileDropdownText}>{name}</p>
            {
                dropdownOpen && (
                    <div className={`flex__column ${style.profileDropdownMenu}`}>
                        {
                            !isSuperUser && (
                                <NavLink 
                                    className={`flex ${style.profileDropdownMenuItem}`} 
                                    to={profilePath} 
                                    state={profileState} 
                                    onClick={handleDropdown}
                                >
                                    <i className={`icon-profile-circle ${style.profileDropdownMenuItemIcon}`} />
                                    <p className={style.profileDropdownMenuItemText}>Профиль</p>
                                </NavLink>
                            )
                        }
                        <div 
                            className={`flex ${style.profileDropdownMenuItem} ${style.profileDropdownMenuItemBordered}`}
                            onClick={handleLogout}
                        >
                            <p className={style.profileDropdownMenuItemText}>Выйти</p>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

// EXPORT
export default ProfileDropdown;