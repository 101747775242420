import { Field, Form } from "react-final-form";
import style from "./PasswordReset.module.css";
import { useEffect, useState } from "react";
import {connect} from "react-redux";
import { resetPassword } from "../../redux/authReducer";
import { useNavigate } from "react-router";
import ButtonNew from "../../components/UI/ButtonNew/ButtonNew";

// PASSWORD RESET FORM
const PasswordResetForm = ({userId, resetPassword}) => {

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE STATE
    const [newPassword, setNewPassword] = useState("");
    const [repeatNewPassword, setRepeatNewPassword] = useState("");
    const [arePasswordsSame, setArePasswordsSame] = useState(false);

    // USE EFFECT
    useEffect(() => {
        if(newPassword && repeatNewPassword) {
            if(newPassword === repeatNewPassword) {
                setArePasswordsSame(true);
            } else {
                setArePasswordsSame(false);
            }
        }
    }, [newPassword, repeatNewPassword]);

    // ON SUBMIT CALLBACK
    const onSubmit = function(values) {
        resetPassword(userId, newPassword)
        .then(response => {
            navigate(`/user/${userId}`, {state: {user: {id: userId}}});
        })
    };

    // FORM
    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className={`flex__column ${style.passwordResetForm}`}>
                        <div className={`flex ${style.passwordResetFormInputs}`}>
                            <label className={`text__label ${style.passwordResetFormInputsLabel}`}>Новый пароль</label>
                            <Field
                                name="newPassword"
                                render={({ input }) => (
                                    <input
                                        {...input}
                                        type="password"
                                        onChange={(e) => {
                                            setNewPassword(e.target.value);
                                        }}
                                        value={newPassword}
                                        className={`input ${style.passwordResetFormInputsInput}`}
                                    />
                                )}
                            />
                        </div>
                        <div className={`flex ${style.passwordResetFormInputs} ${style.topMargin}`}>
                            <label className={`text__label ${style.passwordResetFormInputsLabel}`}>Повторите новый пароль</label>
                            <Field
                                name="repeatNewPassword"
                                render={({ input }) => (
                                    <input
                                        {...input}
                                        type="password"
                                        onChange={(e) => {
                                            setRepeatNewPassword(e.target.value);
                                        }}
                                        value={repeatNewPassword}
                                        className={`input ${style.passwordResetFormInputsInput}`}
                                    />
                                )}
                            />
                        </div>
                        <ButtonNew
                            type="submit"
                            text="Сбросить пароль"
                            className="button_gradient"
                            disabled={!arePasswordsSame}
                        />
                    </div>
                </form>
            )}
        />
    )
};

// PAGE FOR RESETTING PASSWORD
const PasswordReset = ({userId, resetPassword}) => {

    // LAYOUT
    return (
        <div className={`flex__column ${style.passwordReset}`}>
            <h2>Сброс пароля</h2>
            <p className={style.passwordResetText}>Придумайте новый пароль</p>
            <PasswordResetForm userId={userId} resetPassword={resetPassword} />
        </div>
    );
};

// EXPORT
const mapStateToProps = (state) => {
    return {
        userId: state.auth.profileData.id,
    };
};
export default connect(mapStateToProps, {resetPassword})(PasswordReset);