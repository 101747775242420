import { useLocation } from "react-router";
import style from "./Test.module.css";
import TestBlock from "./TestBlock/TestBlock";
import TestProgress from "./TestProgress/TestProgress";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTestCandidate } from "../../redux/testsReducer";
import { createCandidateResult, createResult } from "../../redux/resultsReducer";
import { startLoadingActionCreator } from "../../redux/preloaderReducer";
import Preloader from "../../components/UI/Preloader/Preloader";

// TEST PAGE
const Test = () => {

    // USE DISPATCH
    const dispatch = useDispatch();

    // USE SELECTOR
    const { currentTest } = useSelector(state => state.tests);
    const { isLoading } = useSelector(state => state.preloader);
    const {result} = useSelector(state => state.results);

    // GETTING THE TOKEN AND TEST ID FROM URL
    const query = new URLSearchParams(useLocation().search);
    const token = query.get("token");
    const test = query.get("test");

    // SETTING TOKEN TO LOCAL STORAGE
    localStorage.setItem("candidateToken", token);

    // USE EFFECT
    useEffect(() => {
        if (test) {
            dispatch(startLoadingActionCreator());
            if(!result.id) {
                dispatch(createCandidateResult(test));
            }
            dispatch(getTestCandidate(test));
        }
    }, [test]);

    if (isLoading) return <Preloader />;

    // LAYOUT
    return (
        currentTest && <div className={`flex ${style.test}`}>
            <TestProgress questionsTotal={currentTest?.questions?.length} />
            <div className={`flex__column ${style.testTests}`}>
                {
                    currentTest?.questions?.map((question, index) => {
                        return (
                            <TestBlock
                                question={question}
                                currentQuestionNumber={index + 1}
                                totalQuestions={currentTest?.questions?.length}
                            />
                        )
                    })
                }
            </div>
            <div className={style.background}></div>
        </div>
    );
};

// EXPORT
export default Test;