import { connect } from "react-redux";
import Header from "./Header";

// MAP STATE TO PROPS
const mapStateToProps = (state) => {
    return {
        role: state.auth.role,
    };
};

// CONTAINER COMPONENT FOR HEADER
export const HeaderContainer = connect(mapStateToProps, {})(Header); 