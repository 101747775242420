import { testsAPI } from "../api/api";
import { errorToast, successToast } from "../utils/toasts/toasts";

// ACTION TYPES
const SET_ANSWER = "SET_ANSWER";
const SET_GRADATION_ANSWER = "SET_GRADATION_ANSWER";
const RESET_ANSWERS = "RESET_ANSWERS";
const SET_SENT = "SET_SENT";

// INITIAL STATE
const initialState = {
    answers: [],
};

// ANSWERS REDUCER
export const answersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ANSWER: {
            // Check if the question already exists in the answers
            const questionExists = state.answers.some(answer => answer.question === action.question);

            let newAnswers;
            if (questionExists) {
                // If question exists, map over answers to find the correct question
                newAnswers = state.answers.map(answer => {
                    if (answer.question !== action.question) {
                        // If it's not the question we're looking for, return as is
                        return answer;
                    } else {
                        // If it's the question we're looking for:
                        if (answer.answers.includes(action.answer)) {
                            // If answer already exists, remove it
                            return {
                                ...answer,
                                answers: answer.answers.filter(a => a !== action.answer)
                            };
                        } else {
                            // If answer doesn't exist, add it
                            return {
                                ...answer,
                                index: action.index,
                                answers: [...answer.answers, action.answer]
                            };
                        }
                    }
                });
            } else {
                // If question doesn't exist, add new question and answer
                newAnswers = [
                    ...state.answers,
                    { question: action.question, index: action.index, answers: [action.answer] }
                ];
            }

            // Return new state with the new answers
            return { ...state, answers: newAnswers };
        }
        case SET_GRADATION_ANSWER: {
            // Check if the question already exists in the answers
            const questionIndex = state.answers.findIndex(answer => answer.question === action.question);

            let newAnswers;
            if (questionIndex !== -1) {
                // If question exists, copy the current question object
                let currentQuestion = { ...state.answers[questionIndex] };
                // Insert the new answer at the specified index, and shift other answers
                currentQuestion.answers.splice(action.index, 0, action.answer);
                // Replace the current question with the updated question in the answers array
                newAnswers = state.answers.map((answer, index) => index === questionIndex ? currentQuestion : answer);
            } else {
                // If question doesn't exist, add new question and answer
                newAnswers = [
                    ...state.answers,
                    { question: action.question, answers: [action.answer] } // Assuming the new answer should be inserted at index 0
                ];
            }

            // Return new state with the new answers
            return { ...state, answers: newAnswers };
        }
        case RESET_ANSWERS: {
            return { ...state, answers: [] };
        }
        case SET_SENT: {
            return {
                ...state, answers: state.answers.map(answer => {
                    if (answer.question === action.question) {
                        return { ...answer, wasSent: true, id: action.id };
                    }
                    return answer;
                })
            }
        }
        default: {
            return state;
        }
    };
};

// ACTION CREATORS
export const setAnswerActionCreator = (question, answer, index) => ({ type: SET_ANSWER, question, answer, index });
export const setGradationAnswerActionCreator = (question, answer, index) => ({ type: SET_GRADATION_ANSWER, question, answer, index });
export const resetAnswersActionCreator = () => ({ type: RESET_ANSWERS });
export const setQuestionSentActionCreator = (question, id) => ({ type: SET_SENT, question, id });

// THUNKS
export const sendAnswer = (question, answers, wasSent, resultId, id = null) => dispatch => {
    return new Promise((resolve, reject) => {
        if (!wasSent) {
            testsAPI.sendAnswer(question, answers, resultId)
                .then(response => {
                    if (response.status === 201) {
                        // successToast("Ответ Записан!");
                        dispatch(setQuestionSentActionCreator(question, response.data.id));
                        resolve(response);  // resolve the promise
                    }
                })
                .catch(error => {
                    errorToast(error.message);
                })
        } else {
            testsAPI.updateAnswer(question, answers, id)
                .then(response => {
                    if (response.status === 201) {
                        successToast("Ответ Обновлен!");
                        resolve(response);
                    }
                })
                .catch(error => {
                    errorToast(error.message);
                })
        }
    })
}