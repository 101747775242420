import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// SUCCESS TOAST
export const successToast = (text) => {
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000, // Time in milliseconds after which the toast will be automatically closed
    });
};

// ERROR TOAST
export const errorToast = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000, // Time in milliseconds after which the toast will be automatically closed
    });
  };