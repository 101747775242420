import { useLocation, useNavigate } from "react-router";
import BreadCrumbs from "../../../components/functional/BreadCrumbs/BreadCrumbs";
import style from "./CompaniesView.module.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoadingActionCreator } from "../../../redux/preloaderReducer";
import Preloader from "../../../components/UI/Preloader/Preloader";
import { blockCompany, deleteCompany, getCompany, unblockCompany } from "../../../redux/companiesReducer";
import NavigationBar from "../../../components/UI/NavigationBar/NavigationBar";
import CRUDHeader from "../../../components/UI/CRUDHeader/CRUDHeader";
import InfoBlock from "../../../components/UI/InfoBlock/InfoBlock";
import { convertDateFromISO } from "../../../utils/dateConverter/convertDateFromISO";
import { convertDateFromHyphen } from "../../../utils/dateConverter/convertDateFromHyphen";

// COMAPNY VIEW PAGE
const CompaniesView = () => {

    // USE SELECTOR
    const { isLoading } = useSelector(state => state.preloader);
    const { currentCompany } = useSelector(state => state.companies);
    const {role} = useSelector(state => state.auth);

    // IS IT SYSTEM ADMIN
    const isSuperUser = role === "super";

    // STATE FROM LOCATION
    const { state } = useLocation();

    // USE DISPATCH
    const dispatch = useDispatch();

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE EFFECT
    useEffect(() => {
        if (state?.id) {
            dispatch(startLoadingActionCreator());
            dispatch(getCompany(state.id));
        }
    }, [state?.id, dispatch]);

    const links = [
        ...isSuperUser ? [{
            path: "/companies",
            icon: "icon-buildings-2",
            current: false,
            text: "Компании",
        }] : [],
        {
            path: `/companies/${state?.id}`,
            image: currentCompany?.logo,
            current: true,
            text: currentCompany?.name,
            state: { id: state.id },
        },
    ];

    // LINKS ARRAY FOR NAVIGATION BAR
    const linksNavigation = [
        {
            image: currentCompany?.logo,
            text: "Компания",
            current: true,
            path: `/companies/${state?.id}`,
            state: {id: state?.id},
        },
        {
            icon: "icon-people",
            text: "Сотрудники",
            current: false,
            path: `/companies/${state?.id}/staff`,
            state: {company: currentCompany},
        },
        {
            icon: "icon-money-tick",
            text: "Платежи",
            current: false,
            path: "/payments",
        },
        ...!isSuperUser ? [{
            icon: "icon-chart",
            text: "Тарифы",
            current: false,
            path: "/tariffs",
        }]
        : [],
    ];

    // BUTTONS FOR NAVIGATION BAR
    const buttonsNavigation = [
        currentCompany?.active ?
        {
            text: "Заблокировать",
            class: "button_error_outline",
            onClick: () => {
                // LOGIC TO GET DATE A YEAR LATER FROM NOW
                const currentDate = new Date();
                const oneYearLater = new Date(currentDate);
                oneYearLater.setFullYear(currentDate.getFullYear() + 1);

                const formattedDate = oneYearLater.toISOString();
                dispatch(blockCompany(formattedDate, state?.id));
            },
        } : {
            text: "Разблокировать",
            class: "button_gradient",
            onClick: () => {
                const currentDate = new Date();
                const oneYearLater = new Date(currentDate);
                oneYearLater.setFullYear(currentDate.getFullYear() + 1);

                const formattedDate = oneYearLater.toISOString();
                dispatch(unblockCompany(formattedDate, state?.id));
            },
        },
        {
            text: "Удалить",
            class: "button_error",
            onClick: () => {
                dispatch(deleteCompany(state?.id))
                    .then(response => {
                        navigate("/companies");
                    })
            },
        },
    ];

    // BUTTONS FOR HEADER
    const buttonsHeader = [
        {
            text: "Изменить",
            className: "button_gradient",
            icon: "icon-edit-2",
            onClick: () => {
                navigate(`/companies/edit/${state?.id}`, {state: {company: {id: state?.id}}});
            },
        },
    ];

    // DATA TO DISPLAY IN INFO BLOCK
    const companyValues = [
        {
            label: "Название компании",
            value: currentCompany?.name,
        },
        {
            label: "Активна до",
            value: convertDateFromISO(currentCompany?.active_till),
        },
        {
            label: "Дата регистрации",
            value: currentCompany?.created_at && convertDateFromHyphen(currentCompany?.created_at),
        },
    ];
    const adminValues = [
        {
            label: "Имя",
            value: currentCompany?.users.find(user => user.role === "admin")?.name,
        },
        {
            label: "Электронная почта",
            value: currentCompany?.users.find(user => user.role === "admin")?.email,
        },
        {
            label: "Номер телефона",
            value: currentCompany?.users.find(user => user.role === "admin")?.phone,
        },
    ];

    // IF LOADING RETURN PRELAODER
    if (isLoading) return <Preloader />;

    // LAYOUT
    return (
        <div className={`flex__column ${style.view}`}>
            <BreadCrumbs links={links} />
            <div className={`flex ${style.viewMain}`}>
                <NavigationBar links={linksNavigation} buttons={isSuperUser ? buttonsNavigation : []} />
                <div className={`flex__column ${style.viewMainData}`}>
                    <CRUDHeader
                        image={currentCompany?.logo}
                        name={currentCompany?.name}
                        buttons={buttonsHeader}
                    />
                    <InfoBlock values={companyValues} heading="Данные о компании" />
                    {
                        currentCompany?.users.length 
                        ? <InfoBlock values={adminValues} heading="Администратор компании" />
                        : null
                    }
                </div>
            </div>
        </div>
    );
};

// EXPORT 
export default CompaniesView;