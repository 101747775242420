import { authAPI, usersAPI } from "../api/api";
import { handleLoginSuccess, refreshToken, removeToken } from "../utils/token/token";
import { errorToast, successToast } from "../utils/toasts/toasts";
import { startLoadingActionCreator } from "./preloaderReducer";
import { getCompany } from "./companiesReducer";

// ACTIONS
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

// INITIAL STATE OF AUTH
const initialState = {
    profileData: {
        id: null,
        name: null,
        email: null,
        phone: null,
    },
    accessed_tests: [],
    role: null,
    companyId: null,
    isAuth: false,
};

// REDUCER
export const authReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOGIN:
            return {
                ...state,
                profileData: {
                    id: action.id,
                    name: action.profile.name,
                    email: action.profile.email,
                    phone: action.profile.phone,
                },
                accessed_tests: action.profile.accessed_tests,
                role: action.profile.role,
                companyId: action.profile.company,
                isAuth: true,
            };
        case LOGOUT:
            return {
                ...state,
                profileData: null,
                accessed_tests: [],
                role: null,
                companyId: null,
                isAuth: false,
            };
        default:
            return state;
    }
}

// ACTION CREATORS
export const loginActionCreator = (profile, id) => ({type: LOGIN, profile, id});
export const logoutActionCreator = () => ({type: LOGOUT});

// THUNKS
export const login = (email, password, navigate) => (dispatch) => {

    authAPI.login(email, password)
    .then(response => {
        if(response.status === 200) {
            handleLoginSuccess(response.data.access, response.data.refresh);
            const userId = response.data.user_id;
            usersAPI.getUser(userId)
            .then(response => {
                dispatch(loginActionCreator(response.data, userId));
                successToast("Вы успешно вошли!");
                if(response.data.role === "user") {
                    navigate("/", {state: {id: userId}});
                } else {
                    navigate("/");
                }
            });
        };
    })
    .catch(error => {
        errorToast("Неправильная почта или пароль!");
    })
};

export const logout = () => (dispatch) => {
    removeToken();
    dispatch(logoutActionCreator());
};

export const register = (name, email, password, phone, role, navigate, companyId, user_groups) => (dispatch) => {
    authAPI.register(name, email, password, phone, role, companyId, user_groups)
    .then(response => {
        if(response.status === 201) {
            successToast("Пользователь успешно создан!");
            navigate("/");
        }
    })
    .catch(error => {
        errorToast("Что-то пошло не так!");
    })
};
export const verifyToken = (token, navigate, currentPath) => dispatch => {
    authAPI.verifyToken(token)
    .then(response => {
        if(response.status === 200) {
            const userId = response.data.user_id;
            usersAPI.getUser(userId)
            .then(response => {
                dispatch(loginActionCreator(response.data, userId));
            });
        };
    })
    .catch(error => {
        if(currentPath !== "/registration") {
            navigate("/login");
        };
        dispatch(logoutActionCreator());
    })
};
export const refreshTokenThunk = (refresh, navigate) => () => {
    authAPI.refreshToken(refresh)
    .then(response => {
        if(response.status === 200) {
            refreshToken(response.data.access);
        }
    })
    .catch(error => {
        navigate("/login");
    })
};
export const resetPassword = (userId, password) => () => {
    return authAPI.resetPassword(userId, password)
    .then(response => {
        if(response.status === 200) {
            successToast("Вы успешно поменяли пароль");
        }
    })
};