
// FUNCTION FOR GROUPING TESTS BY TYPE: PROFESSIONAL, EMOTIONAL, etc.
export const groupTestsByType = function(tests) {
    const groupedByType = tests.reduce((acc, test) => {
        if (!acc[test.type]) {
          acc[test.type] = [];
        }
        acc[test.type].push(test);
        return acc;
      }, {});
      
    const result = Object.keys(groupedByType).map(type => ({
        type: type,
        tests: groupedByType[type]
      }));
    
    // RETURNING THE RESULT
    return result;
};