import HeaderLogo from "../HeaderLogo/HeaderLogo";
import logo from "../../../assets/images/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import style from "./Header.module.css";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import { useSelector } from "react-redux";

// COMPONENT
const Header = ({ role }) => {

    // USE LOCATION
    const location = useLocation();
    // IS IT USER?
    const isUser = role === "user";

    // IS IT ADMIN OR MODERATOR?
    const isAdmin = role === "admin" || role === "moderator";
    const isSuperUser = role === "super";

    // GETTING USER DATA
    const authState = useSelector(state => state.auth);

    // LAYOUT
    return (
        <div className={`flex ${style.flex_header}`}>
            <HeaderLogo source={logo} />
            <div className={`flex ${style.flex_menu}`}>
                {
                    (isSuperUser || isAdmin) && (
                        <NavLink className={`${style.header_link} ${location.pathname === "/" && style.active}`} to="/">
                            Главная
                        </NavLink>
                    )
                }
                {
                    isSuperUser && (
                        <NavLink className={`${style.header_link} ${location.pathname.includes("/companies") && style.active}`} to="/companies">
                            Компании
                        </NavLink>
                    )
                }
                {
                    isAdmin && (
                        <NavLink
                            className={`${style.header_link} ${location.pathname.includes("/companies") && style.active}`}
                            to={`/companies/${authState?.companyId}`}
                            state={{ id: authState?.companyId }}
                        >
                            Компания
                        </NavLink>
                    )
                }
                {
                    isSuperUser && (
                        <NavLink className={`${style.header_link} ${location.pathname.includes("/applications") && style.active}`} to="/applications">
                            Заявки
                        </NavLink>
                    )
                }
                {
                    (isSuperUser || isAdmin || isUser) && (
                        <NavLink className={`${style.header_link} ${location.pathname.includes("/tests") && style.active}`} to="/tests/assign">
                            Тестирование
                        </NavLink>
                    )
                }
                {
                    isUser && (
                        <NavLink 
                            className={`${style.header_link} ${(location.pathname === "/" || location.pathname === "/edit") && style.active}`} 
                            to="/"
                            state={{id: authState.profileData.id}}
                        >
                            Профиль
                        </NavLink>
                    )
                }
            </div>
            {
                (isSuperUser || isAdmin || isUser) && <ProfileDropdown name={authState?.profileData?.name} />
            }
        </div>
    );
};

// EXPORTING HEADER
export default Header;