import { useLocation, useNavigate } from "react-router";
import BreadCrumbs from "../../../components/functional/BreadCrumbs/BreadCrumbs";
import style from "./TestsEdit.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTest, updateTest } from "../../../redux/testsReducer";
import NavigationBar from "../../../components/UI/NavigationBar/NavigationBar";
import CRUDHeader from "../../../components/UI/CRUDHeader/CRUDHeader";
import { formatDuration } from "../../../utils/durationConverter/durationConverter";
import InfoBlock from "../../../components/UI/InfoBlock/InfoBlock";
import FileDrop from "../../../components/functional/FileDrop/FileDrop";
import Input from "../../../components/UI/Input/Input";
import SelectComponent from "../../../components/functional/SelectComponent/SelectComponent";
import testTypes from "../../../utils/getTestTypes/getTestTypes";
import { setImage } from "../../../utils/imageUpload/setImage";
import QuestionBlock from "../QuestionBlock";
import { timeToMinutes } from "../../../utils/timeToMinutes/timeToMinutes";
import { startLoadingActionCreator } from "../../../redux/preloaderReducer";
import Preloader from "../../../components/UI/Preloader/Preloader";
import ButtonNew from "../../../components/UI/ButtonNew/ButtonNew";

// PAGE FOR EDITING A PAGE
const TestsEdit = () => {

    // STATE FROM LOCATION
    const { state } = useLocation();

    // USE DISPATCH
    const dispatch = useDispatch();

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE STATE
    const [test, setTest] = useState({});
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);

    // USE SELECTOR
    const testsState = useSelector(state => state.tests);
    const { currentTest } = testsState;
    const authState = useSelector(state => state.auth);
    const {isLoading} = useSelector(state => state.preloader);

    // USE EFFECT
    useEffect(() => {
        if (state?.test?.id) {
            dispatch(startLoadingActionCreator());
            dispatch(getTest(state.test.id));
        }
    }, [state?.test?.id]);
    useEffect(() => {
        if (currentTest?.id) {
            setNumberOfQuestions(currentTest.questions.length);
            setTest({
                ...test,
                id: currentTest.id,
                cover_image: currentTest.cover_image,
                name: currentTest.name,
                type: currentTest.type,
                time_allocated: formatDuration(currentTest?.time_allocated),
                questions: currentTest.questions.map((question, index) => {
                    return {
                        id: question.id,
                        text: question.text,
                        index: index,
                        choices: question.choices.map((choice, index) => {
                            return {
                                id: choice.id,
                                text: choice.text,
                                is_correct: choice?.is_correct,
                                index,
                            }
                        })
                    }
                })
            });
        }
    }, [currentTest?.id]);

    const links = [
        {
            path: "/tests",
            icon: "icon-document-normal",
            current: false,
            text: "Центр тестирования",
        },
        {
            path: `/tests/${state?.test?.id}`,
            image: currentTest?.cover_image,
            current: false,
            text: currentTest?.name,
            state: {id: state?.test?.id},
        },
        {
            path: `/tests/edit/${state?.test?.id}`,
            icon: "icon-edit-2",
            current: true,
            text: "Изменение",
            state: {
                test: {
                    id: state?.test?.id,
                }
            },
        },
    ];

    // LINKS ARRAY FOR NAVIGATION BAR
    const linksNavigation = [
        {
            icon: "icon-document-text",
            text: "Назначение",
            current: false,
            path: "/tests/assign",
        },
        {
            icon: "icon-document-normal",
            text: "Тесты",
            current: true,
            path: "/tests",
        },
        {
            icon: "icon-clipboard-tick",
            text: "Результаты",
            current: false,
            path: "/results",
        }
    ];

    // BUTTONS FOR HEADER
    const buttonsHeader = [
        {
            text: "Назад",
            className: "button_transparent",
            onClick: () => {
                navigate(`/tests/${state?.test?.id}`, { state: { id: state?.test?.id } });
            },
        },
        {
            text: "Сохранить",
            className: "button_gradient",
            onClick: () => {
                const formData = new FormData();
                formData.append("name", test.name);
                formData.append("type", test.type);
                formData.append("author", authState.profileData.id);
                if(test.cover_image !== currentTest.cover_image) {
                    formData.append("cover_image", test.cover_image);
                }
                formData.append("time_allocated", timeToMinutes(test.time_allocated));
                if(authState.companyId) {
                    formData.append("company", authState.companyId);
                }
                dispatch(updateTest(formData, test, navigate, currentTest));
            },
        },
    ];

    // HANDLING IMAGE
    const handleCoverImage = (file) => {
        setTest({
            ...test,
            cover_image: file,
        });
        setImage(file, "imagePreview");
        setImage(file, "crumbsImage");
        setImage(file, "crudImage");
    };

    // TEST MODIFICATION FUNCTIONS
    const onQuestionChange = (index) => (event) => {
        setTest({
            ...test,
            questions: test.questions.map((question, i) => {
                if(index === question.index) {
                    return ({...question, text: event.target.value});
                }
                return question;
            })
        });
    };
    const onOptionChange = (questionIndex, choiceIndex) => (event) => {
        setTest({
            ...test,
            questions: test.questions.map((question, i) => {
                if(questionIndex === question.index) {
                    return ({...question, choices: question.choices.map((choice, choiceI) => {
                        if(choice.index === choiceIndex) {
                            return {...choice, text: event.target.value};
                        }
                        return choice;
                    })})
                }
                return question;
            })
        });
    };
    const onQuestionDelete = index => {
        setNumberOfQuestions(prev => prev - 1);
        const updatedQuestions = test.questions
            .filter(question => question.index !== index)
            .map((question, newIndex) => ({ ...question, index: newIndex }));
        setTest({
            ...test,
            questions: updatedQuestions,
        });
    };
    const onCorrectCheckboxChecked = (questionIndex, choiceIndex) => () => {
        setTest({
            ...test,
            questions: test.questions.map((question, i) => {
                if (questionIndex === question.index) {
                    return ({
                        ...question, choices: question.choices.map((choice, choiceI) => {
                            if (choice.index === choiceIndex) {
                                return { ...choice, is_correct: !choice.is_correct };
                            }
                            return choice;
                        })
                    })
                }
                return question;
            })
        });
    };
    const onOptionDelete = (questionIndex, choiceIndex) => () => {
        setTest({
            ...test,
            questions: test.questions.map(question => {
                if(question.index === questionIndex) {
                    return ({
                        ...question,
                        choices: question.choices
                        .filter(choice => choice.index !== choiceIndex)
                        .map((choice, newIndex) => ({ ...choice, index: newIndex }))
                    })
                }
                return question;
            })
        });
    };
    const onAddOption = questionIndex => () => {
        setTest({
            ...test,
            questions: test.questions.map(question => {
                if(question.index === questionIndex) {
                    return ({
                        ...question,
                        choices: [
                            ...question.choices,
                            {
                                text: "",
                                is_correct: false,
                                index: question.choices.length,
                            }
                        ]
                    })
                }
                return question;
            })
        });
    };

    const onAddButtonClick = () => {
        setNumberOfQuestions(previous => previous + 1);
        setTest({
            ...test,
            questions: [
                ...test.questions,
                {
                    text: "",
                    index: numberOfQuestions,
                    choices: [
                        {
                            text: "",
                            is_correct: false,
                            index: 0,
                        },
                        {
                            text: "",
                            is_correct: false,
                            index: 1,
                        },
                        {
                            text: "",
                            is_correct: false,
                            index: 2,
                        },
                        {
                            text: "",
                            is_correct: false,
                            index: 3,
                        },
                    ],
                },
            ],
        });
    };

    // ON COVER IMAGE CHANGE
    const onCoverImageChange = (event) => {
        const file = event?.target?.files[0];
        handleCoverImage(file);
    };

    // ON DROP FILES EVENT
    const onDropFiles = (files) => {
        const file = files[0];
        handleCoverImage(file);
    };

    // ELEMENTS FOR EDITING TEST
    const elements = [
        {
            label: "Обложка",
            isFullWidth: true,
            item: (
                <FileDrop
                    onChange={onCoverImageChange}
                    onDropFiles={onDropFiles}
                    image={test?.cover_image}
                />
            )
        },
        {
            label: "Название теста",
            item: (
                <Input
                    value={test?.name}
                    type="text"
                    id="testName"
                    onChange={(event) => {
                        setTest({
                            ...test,
                            name: event.target.value,
                        });
                    }}
                    placeholder="Название теста"
                />
            )
        },
        {
            label: "Категория",
            item: (
                <SelectComponent
                    value={testTypes.find(type => type.value === test?.type) || testTypes[0]}
                    onChange={(event) => {
                        setTest({
                            ...test,
                            type: event.value,
                        })
                    }}
                    options={testTypes}
                />
            ),
        },
        {
            label: "Длительность",
            item: (
                <Input
                    value={test?.time_allocated}
                    onChange={(event) => {
                        setTest({
                            ...test,
                            time_allocated: event.target.value,
                        });
                    }}
                    placeholder="00:00:00"
                    mask="00:00:00"
                />
            ),
        },
    ];


    if(isLoading) return <Preloader />;
    
    // LAYOUT
    return (
        <div className={`flex__column ${style.edit}`}>
            <BreadCrumbs links={links} />
            <div className={`flex ${style.editMain}`}>
                <NavigationBar links={linksNavigation} buttons={[]} />
                <div className={`flex__column ${style.editMainRight}`}>
                    <CRUDHeader
                        image={test?.cover_image}
                        name={test?.name}
                        category={test?.type}
                        buttons={buttonsHeader}
                    />
                    <InfoBlock heading="Данные о тесте" isEditMode={true} values={elements} />
                    <div className={`flex__column ${style.viewMainDataQuestions}`}>
                        {
                            test?.questions?.map((question, index) => {
                                return (
                                    <QuestionBlock
                                        question={question?.text}
                                        options={question?.choices}
                                        questionNumber={question.index + 1}
                                        index={question.index}
                                        isEditMode={true}
                                        onQuestionChange={onQuestionChange}
                                        onOptionChange={onOptionChange}
                                        onQuestionDelete={onQuestionDelete}
                                        onOptionDelete={onOptionDelete}
                                        numberOfQuestions={numberOfQuestions}
                                        onCorrectCheckboxChecked={onCorrectCheckboxChecked}
                                        onAddOption={onAddOption}
                                        isUpdate={true}
                                    />
                                );
                            })
                        }
                        <div className={style.viewMainDataQuestionsAddButton}>
                                <ButtonNew
                                    text="Добавить вопрос"
                                    className="button_gradient"
                                    icon="icon-add-square"
                                    onClick={onAddButtonClick}
                                />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// EXPORT
export default TestsEdit;