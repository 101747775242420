import style from "./CRUDHeader.module.css";
import noImage from "../../../assets/images/no-image.png";
import ButtonNew from "../ButtonNew/ButtonNew";

// HEADER ON ALL CRUD PAGES
const CRUDHeader = ({ image, name, buttons, category }) => {

    // LAYOUT
    return (
        <div className={`flex ${style.header}`}>
            <div className={`flex ${style.headerNaming}`}>
                <img src={image || noImage} alt="image" className={style.headerNamingImage} id="crudImage" />
                <div className={`flex__column ${style.headerNamingName}`}>
                    <p className={style.headerNamingNameHeader}>{name}</p>
                    {
                        category && <p className={style.headerNamingNameActive}>{category || "Активен"}</p>
                    }
                </div>
            </div>
            <div className={`flex ${style.headerButtons}`}>
                {
                    buttons.map((button, index) => (
                        <ButtonNew
                            key={index}
                            text={button?.text}
                            className={button?.className}
                            icon={button?.icon}
                            onClick={button?.onClick}
                        />
                    ))
                }
            </div>
        </div>
    );
};

// EXPORT
export default CRUDHeader;