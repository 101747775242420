import { NavLink } from "react-router-dom";
import style from "./HeaderLogo.module.css";

// LAYOUT
const HeaderLogo = ({source}) => {
    return (
        <NavLink to="/">
            <img src={source} alt="logo" className={style.logo} />
        </NavLink>
    );
};

// EXPORTING HEADER LOGO
export default HeaderLogo;