import { connect } from "react-redux";
import Companies from "./Companies";
import { getCompanies } from "../../redux/companiesReducer";
import { getUsers } from "../../redux/usersReducer";

// MAP STATE TO PROPS
const mapStateToProps = (state) => {
    return {
        usersTotal: state.users.totalUsers,
        companiesTotal: state.companies.totalCompanies,
        users: state.users.users,
        companies: state.companies.companies,
    };
};

// CONTAINER FOR COMPANIES PAGE
export const CompaniesContainer = connect(mapStateToProps, {getCompanies, getUsers})(Companies);