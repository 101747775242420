import style from "./TestCheckbox.module.css";

// TEST CHECKBOX COMPONENT
const TestCheckbox = ({ index, testName, numberOfQuestions, isChecked, onClick, time }) => {

    // LAYOUT
    return (
        <label htmlFor={`test-${index}`} className={`${style.test} ${isChecked ? style.testChecked : ""}`} onClick={onClick}>
            <div className={`flex ${style.testCheckbox}`}>
                {
                    isChecked 
                    ? <i className={`icon-tick-square ${style.testCheckboxIcon} ${style.testCheckboxIconTicked}`} />
                    : <i className={`icon-checkbox-disabled ${style.testCheckboxIcon}`} />
                }
                <p className={style.testCheckboxText}>{testName}</p>
            </div>
            <div className={`flex ${style.testDataBlock}`}>
                <div className={`flex ${style.testData}`}>
                    <i className={`icon-document-text ${style.testDataIcon}`} />
                    <p className={style.testDataText}>{numberOfQuestions}</p>
                </div>
                {
                    time === "00:00:00"
                        ? null
                        : <div className={`flex ${style.testData}`}>
                            <i className={`icon-timer ${style.testDataIcon}`} />
                            <p className={style.testDataText}>{time}</p>
                        </div>
                }
            </div>
            <input type="checkbox" className={style.checkboxHidden} />
        </label>
    );
};

// EXPORT 
export default TestCheckbox;