// TEST TYPES
const testTypes = [
    {
        value: "professional",
        label: "Professional",
    },
    {
        value: "characteristic",
        label: "Characteristic",
    },
    {
        value: "psychological",
        label: "Psychological",
    },
];

// EXPORTING TYPES
export default testTypes;