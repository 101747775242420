
// FUNCTION TO GENERATE 8-CHARACTER PASSWORD
export function passwordGenerate() {

    // DEFINING LENGTH OF A PASSWORD
    var length = 8;

    // PROVIDING ALL POSSIBLE CHARACTERS
    var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";

    // VALUE TO BE RETURNED LATER
    var retVal = "";

    // RUNNING 8 TIMES AND PICKING RANDOM CHARACTER
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    // RETURNING VALUE
    return retVal;
  }