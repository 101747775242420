import style from "./ButtonNew.module.css";

// CLASSES OF BUTTON
// 1. button_outline (light orange with orange borders and text)
// 1.1. button_outline_small (smaller version of 1)
// 2. button_white (white with gray borders)
// 3. button_gradient (orange gradient without borders)
// 3.1. button_gradient_small (smaller version of 3)
// 4. button_disabled (white with light gray borders)
// 4.1. button_disabled_small (small version of 4)
// 5. button_error (red without borders)
// 5.1. button_error_small (small version of 5)
// 6. button_transparent (transparent with orange borders)
// 7. button_dark (black without borders)
// 7.1. button_dark_small (small version of 7)
// 8. button_error_outline (pink with red borders)

// NEW DESIGN BUTTON
const ButtonNew = ({text, className, icon, disabled, onClick, iconDirection, type}) => {
    
    // LAYOUT
    return (
        <button className={`${style.button} ${style[className]}`} disabled={disabled} onClick={onClick} type={type || "button"}>
            {
                icon && <i className={`${icon} ${style.buttonIcon} ${style[iconDirection]}`} />
            }
            {text || ""}
        </button>
    );
};

// EXPORT
export default ButtonNew;