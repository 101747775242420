import { HeaderContainer } from "../../components/UI/Header/HeaderContainer";
import style from "./MainLayout.module.css";
import Footer from "../../components/UI/Footer/Footer";

// MAIN LAYOUT PAGE
const MainLayout = ({children}) => {

    // LAYOUT
    return (
        <div className={`flex__column ${style.flex__column_main}`}>
            <HeaderContainer />
            <div className={style.content}>
                {children}
            </div>
            <Footer />
        </div>
    );
};

// EXPORTING MAIN LAYOUT
export default MainLayout;