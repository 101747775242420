import style from "./NavigationBar.module.css";
import NavigationBarItem from "./NavigationBarItem/NavigationBarItem";
import ButtonNew from "../ButtonNew/ButtonNew";

// NAVIGATION BAR COMPONENT
const NavigationBar = ({links, buttons}) => {

    // LAYOUT
    return (
        <div className={`flex__column ${style.navigationBar}`}>
            {
                links.map((link, index) => (
                    <NavigationBarItem 
                        key={index}
                        icon={link?.icon}
                        image={link?.image}
                        text={link.text}
                        isActive={link.current}
                        path={link.path}
                        state={link?.state}
                    />
                ))
            }
            {
                buttons.length 
                ? buttons.map((button, index) => <ButtonNew key={index} text={button.text} className={button.class} onClick={button.onClick} />)
                : null
            }
        </div>
    );
};

// EXPORT 
export default NavigationBar;