import { useLocation, useNavigate } from "react-router";
import BreadCrumbs from "../../../components/functional/BreadCrumbs/BreadCrumbs";
import style from "./Departments.module.css";
import NavigationBar from "../../../components/UI/NavigationBar/NavigationBar";
import { useDispatch, useSelector } from "react-redux";
import { deleteCompany } from "../../../redux/companiesReducer";
import CRUDHeader from "../../../components/UI/CRUDHeader/CRUDHeader";
import { useEffect, useState } from "react";
import Input from "../../../components/UI/Input/Input";
import ButtonNew from "../../../components/UI/ButtonNew/ButtonNew";
import { startLoadingActionCreator } from "../../../redux/preloaderReducer";
import { createUserGroup, getUserGroupsOfCompany, setUserGroupsActionCreator } from "../../../redux/userGroupsReducer";
import Preloader from "../../../components/UI/Preloader/Preloader";
import TableComponent from "../../../components/functional/Table/Table";
import { errorToast } from "../../../utils/toasts/toasts";

// DEPARTMENTS PAGE
const Departments = () => {

    // USE STATE
    const [rows, setRows] = useState([]);
    const [departments, setDepartments] = useState([
        {
            name: "",
            index: 0,
        },
    ]);

    // CALLBACKS
    const onDepartmentChange = index => (event) => {
        setDepartments(departments.map(department => {
            if(department.index === index) {
                return {...department, name: event.target.value};
            }
            return department;
        }))
    };
    const deleteDepartment = index => () => {
        setDepartments(departments
            .filter(department => department.index !== index)
            .map((department, index) => ({...department, index})));
    };
    const addDepartment = () => {
        setDepartments([
            ...departments,
            {
                name: "",
                index: departments.length,
            },
        ]);
    }

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE LOCATION
    const {state} = useLocation();
    const location = useLocation();

    // USE DISPATCH
    const dispatch = useDispatch();

    // USE SELECTOR
    const {role} = useSelector(state => state.auth);
    const {isLoading} = useSelector(state => state.preloader);
    const {userGroups} = useSelector(state => state.userGroups);

    const isSuperUser = role === "super";

    // USE EFFECT
    useEffect(() => {
        dispatch(startLoadingActionCreator());
        dispatch(getUserGroupsOfCompany(state?.company?.id));
    }, [state?.company?.id]);
    useEffect(() => {
        if(userGroups?.length) {
            const userGroupsRows = userGroups.map(userGroup => ({
                id: userGroup.id,
                name: userGroup.name,
                numberOfEmployees: "-",
                created_at: "-",
                icon: "icon-dots",
            }));
            setRows(userGroupsRows);
        }
    }, [userGroups?.length]);

    // IF USER NAVIGATES AWAY FROM PAGE WE RESET STATE
    useEffect(() => {
        return () => {
            dispatch(setUserGroupsActionCreator([]));
        };
    }, [location]);

    // LINKS ARRAY FOR BREADCRUMBS
    const links = [
        ...isSuperUser ?
        [{
            path: "/companies",
            icon: "icon-buildings-2",
            current: false,
            text: "Компании",
        }]
        : [],
        {
            path: `/companies/${state?.company?.id}`,
            image: state?.company?.logo,
            current: false,
            text: state?.company?.name,
            state: { id: state?.company?.id },
        },
        {
            path: `/companies/${state?.company?.id}/staff`,
            icon: "icon-people",
            current: false,
            text: "Сотрудники",
            state: { company: state?.company },
        },
        {
            path: `/companies/${state?.company?.id}/departments`,
            icon: "icon-add-square",
            current: true,
            text: "Добавление отдела",
        }
    ];

    // LINKS ARRAY FOR NAVIGATION BAR
    const linksNavigation = [
        {
            image: state?.company?.logo,
            text: "Компания",
            current: false,
            path: `/companies/${state?.company?.id}`,
            state: { id: state?.company?.id },
        },
        {
            icon: "icon-people",
            text: "Сотрудники",
            current: true,
            path: `/companies/${state?.company?.id}/staff`,
            state: { company: state?.company },
        },
        {
            icon: "icon-money-tick",
            text: "Платежи",
            current: false,
            path: "/payments",
        },
        ...!isSuperUser ? [{
            icon: "icon-chart",
            text: "Тарифы",
            current: false,
            path: "/tariffs",
        }]
            : [],
    ];

    // BUTTONS FOR NAVIGATION BAR
    const buttons = [
        {
            text: "Удалить",
            class: "button_error",
            onClick: () => {
                dispatch(deleteCompany(state?.company?.id))
                    .then(response => {
                        navigate("/companies");
                    })
            },
        },
    ];

    // BUTTONS FOR HEADER
    const buttonsHeader = [
        {
            text: "Назад",
            className: "button_transparent",
            onClick: () => {
                navigate(`/companies/${state?.company?.id}/staff`, {state: {company: state?.company}});
            },
        },
        {
            text: "Сохранить",
            className: "button_gradient",
            onClick: () => {
                if(departments.some(department => department.name === "")) {
                    errorToast("Вы оставили пустое поле");
                    return;
                }
                setDepartments([{
                    name: "",
                    index: 0,
                },]);
                departments.forEach(department => {
                    dispatch(createUserGroup(department.name, department.name, state?.company?.id));
                });
            },
        },
    ];

    // COLUMNS OF A TABLE
    const columns = [
        "Отдел",
        "Количество сотрудников",
        "Дата создания",
        "icon-dots"
    ];

    if(isLoading) return <Preloader />;

    // LAYOUT
    return (
        <div className={`flex__column ${style.departments}`}>
            <BreadCrumbs links={links} />
            <div className={`flex ${style.departmentsMain}`}>
                <NavigationBar links={linksNavigation} buttons={isSuperUser ? buttons : []} />
                <div className={`flex__column ${style.viewMainData}`}>
                    <CRUDHeader
                        image={state?.company?.logo}
                        name={state?.company?.name}
                        buttons={buttonsHeader}
                    />
                    <div className={`flex__column ${style.viewMainDataRows}`}>
                        <h3 className={style.viewMainDataRowsHeading}>Добавление отдела</h3>
                        <div className={`flex__column ${style.viewMainDataRowsDepartments}`}>
                            {
                                departments.map((department, index) => {
                                    return (
                                        <div 
                                            className={`flex__column 
                                            ${style.viewMainDataRowsDepartmentsSingle} 
                                            ${index === departments.length - 1 ? style.viewMainDataRowsDepartmentsSingleLast : ""}`} 
                                            key={index}
                                        >
                                            <p className={style.viewMainDataRowsDepartmentsSingleText}>Название отдела</p>
                                            <div className={`flex ${style.viewMainDataRowsDepartmentsSingleInput}`}>
                                                <Input 
                                                    type="text"
                                                    id={department.index}
                                                    onChange={onDepartmentChange(department.index)}
                                                    placeholder="Маркетинг"
                                                    value={department.name}
                                                />
                                                {
                                                    (index === 0 && departments.length === 1) 
                                                    ? null
                                                    : <ButtonNew 
                                                        className="button_error_small" 
                                                        icon="icon-trash"
                                                        onClick={deleteDepartment(department.index)} 
                                                    /> 
                                                }
                                                {
                                                    index === departments.length - 1 &&
                                                    <ButtonNew 
                                                        className="button_gradient_small"
                                                        icon="icon-add-square"
                                                        onClick={addDepartment}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <TableComponent
                        columns={columns} 
                        rows={rows} 
                        entity="departments" 
                        headerText="Отделы" 
                    />
                </div>
            </div>
        </div>
    );
};

// EXPORT
export default Departments;