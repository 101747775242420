import { useLocation, useNavigate } from "react-router";
import style from "./Staff.module.css";
import BreadCrumbs from "../../components/functional/BreadCrumbs/BreadCrumbs";
import NavigationBar from "../../components/UI/NavigationBar/NavigationBar";
import { useDispatch, useSelector } from "react-redux";
import { blockCompany, deleteCompany, unblockCompany } from "../../redux/companiesReducer";
import TableComponent from "../../components/functional/Table/Table";
import CRUDHeader from "../../components/UI/CRUDHeader/CRUDHeader";
import { useEffect, useState } from "react";
import { convertDateFromHyphen } from "../../utils/dateConverter/convertDateFromHyphen";

// STAFF TABLE PAGE
const Staff = () => {

    // USE STATE
    const [rows, setRows] = useState([]);

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE LOCATION
    const { state } = useLocation();

    // USE SELECTOR
    const { role } = useSelector(state => state.auth);

    // USE DISPATCH
    const dispatch = useDispatch();

    const isSuperUser = role === "super";

    // USE EFFECT
    useEffect(() => {
        if(state?.company?.users?.length) {
            const usersRows = state.company.users.map(user => ({
                id: user.id,
                name: user.name,
                user_group: user.user_groups[0]?.name || "-",
                email: user.email,
                created_at: convertDateFromHyphen(user.created_at),
                isAdmin: user.role === "user" ? "-" : "Да",
                icon: "icon-dots",
            }));
            setRows(usersRows);
        }
    }, [state?.company?.users?.length]);

    // LINKS ARRAY FOR BREADCRUMBS
    const links = [
        ...isSuperUser ? 
        [{
            path: "/companies",
            icon: "icon-buildings-2",
            current: false,
            text: "Компании",
        }] :
        [],
        {
            path: `/companies/${state?.company?.id}`,
            image: state?.company?.logo,
            current: false,
            text: state?.company?.name,
            state: { id: state?.company?.id },
        },
        {
            path: `/companies/${state?.company?.id}/staff`,
            icon: "icon-people",
            current: true,
            text: "Сотрудники",
            state: { company: state?.company },
        },
    ];

    // LINKS ARRAY FOR NAVIGATION BAR
    const linksNavigation = [
        {
            image: state?.company?.logo,
            text: "Компания",
            current: false,
            path: `/companies/${state?.company?.id}`,
            state: { id: state?.company?.id },
        },
        {
            icon: "icon-people",
            text: "Сотрудники",
            current: true,
            path: `/companies/${state?.company?.id}/staff`,
            state: { company: state?.company },
        },
        {
            icon: "icon-money-tick",
            text: "Платежи",
            current: false,
            path: "/payments",
        },
        ...!isSuperUser ? [{
            icon: "icon-chart",
            text: "Тарифы",
            current: false,
            path: "/tariffs",
        }]
            : [],
    ];

    // BUTTONS FOR NAVIGATION BAR
    const buttons = [
        {
            text: "Удалить",
            class: "button_error",
            onClick: () => {
                dispatch(deleteCompany(state?.company?.id))
                    .then(response => {
                        navigate("/companies");
                    })
            },
        },
    ];

    // BUTTONS FOR HEADER
    const buttonsHeader = [
        {
            text: "Добавить отдел",
            className: "button_gradient",
            icon: "icon-add-square",
            onClick: () => {
                navigate(`/companies/${state?.company?.id}/departments`, {state: {company: state?.company}});
            },
        },
    ];

    // COLUMNS OF A TABLE
    const columns = [
        "Имя сотрудника",
        "Отдел",
        "Почта сотрудника",
        "Регистрация",
        "Менеджер",
        "icon-dots"
    ];

    // LAYOUT
    return (
        <div className={`flex__column ${style.staff}`}>
            <BreadCrumbs links={links} />
            <div className={`flex ${style.staffMain}`}>
                <NavigationBar links={linksNavigation} buttons={isSuperUser ? buttons : []} />
                <div className={`flex__column ${style.viewMainData}`}>
                    <CRUDHeader
                        image={state?.company?.logo}
                        name={state?.company?.name}
                        buttons={buttonsHeader}
                    />
                    <TableComponent 
                        columns={columns} 
                        rows={rows}
                        headerText="Сотрудники" 
                        entity="staff"
                        headerButtonText="Добавить сотрудника" 
                        page={`/companies/${state?.company?.id}/staff`}
                        state={{company: state?.company}}
                    />
                </div>
            </div>
        </div>
    );
};

// EXPORT
export default Staff;