
// ACTION TYPES
const START_LOADING = "START_LOADING";
const STOP_LOADING = "STOP_LOADING";
const START_COMPONENT_LOADING = "START_COMPONENT_LOADING";
const STOP_COMPONENT_LOADING = "STOP_COMPONENT_LOADING";

// INITAL STATE
const initialState = {
    isLoading: false,
    isComponentLoading: false,
};

// PRELOADER REDUCER
export const preloaderReducer = (state = initialState, action) => {
    switch(action.type) {
        case START_LOADING:
            return {...state, isLoading: true};
        case STOP_LOADING:
            return {...state, isLoading: false};
        case START_COMPONENT_LOADING:
            return {...state, isComponentLoading: true};
        case STOP_COMPONENT_LOADING:
            return {...state, isComponentLoading: false};
        default:
            return state;
    }
};

// ACTION CREATORS
export const startLoadingActionCreator = () => ({type: START_LOADING});
export const stopLoadingActionCreator = () => ({type: STOP_LOADING});
export const startComponentLoadingActionCreator = () => ({type: START_COMPONENT_LOADING});
export const stopComponentLoadingActionCreator = () => ({type: STOP_COMPONENT_LOADING});