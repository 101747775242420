import { useEffect, useState } from "react";
import { AssignTestsContainer } from "../../components/functional/AssignTests/AssignTestsContainer";
import style from "./TestsAssign.module.css";
import TestCheckbox from "../../components/functional/TestCheckbox/TestCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { getTests, removeSelectedTest, selectTestActionCreator } from "../../redux/testsReducer";
import { groupTestsByType } from "../../utils/groupTestsByType/groupTestsByType";
import { startLoadingActionCreator } from "../../redux/preloaderReducer";
import Preloader from "../../components/UI/Preloader/Preloader";
import BreadCrumbs from "../../components/functional/BreadCrumbs/BreadCrumbs";
import ChoiceTab from "../../components/functional/ChoiceTab/ChoiceTab";
import { formatDuration } from "../../utils/durationConverter/durationConverter";
import TestsCreate from "../Tests/Create/TestsCreate";
import NavigationBar from "../../components/UI/NavigationBar/NavigationBar";

// PAGE FOR ASSIGNING TESTS TO CANDIDATES
const TestsAssign = () => {

    // USE STATE
    const [isCreateTests, setIsCreateTests] = useState(false);
    const [isMySet, setIsMySet] = useState(true);
    const [testsCategories, setTestsCategories] = useState([]);

    // USE DISPATCH
    const dispatch = useDispatch();

    // USE SELECTOR
    const testsState = useSelector(state => state.tests);
    const { isLoading } = useSelector(state => state.preloader);

    // LINKS ARRAY FOR BREADCRUMBS
    const links = [
        {
            path: "/tests/assign",
            icon: "icon-document-text",
            current: true,
            text: "Назначение",
        },
    ];

    // LINKS ARRAY FOR NAVIGATION BAR
    const linksNavigation = [
        {
            icon: "icon-document-text",
            text: "Назначение",
            current: true,
            path: "/tests/assign",
        },
        {
            icon: "icon-document-normal",
            text: "Тесты",
            current: false,
            path: "/tests",
        },
        {
            icon: "icon-clipboard-tick",
            text: "Результаты",
            current: false,
            path: "/results",
        }
    ];

    // USE EFFECT
    useEffect(() => {
        dispatch(startLoadingActionCreator());
        dispatch(getTests());
    }, []);
    useEffect(() => {
        if (testsState.tests.length) {
            setTestsCategories(groupTestsByType(testsState.tests));
        }
    }, [testsState.tests]);

    // SELECTING TEST
    const selectTest = (testId) => () => {
        dispatch(selectTestActionCreator(testId));
    };

    // UNSELECTING TEST
    const unselectTest = (testId) => () => {
        dispatch(removeSelectedTest(testId));
    };

    // WHEN CHECKBOX IS CLICKED
    const onCheckboxClick = (isChecked, testId) => () => {
        !isChecked ? dispatch(selectTest(testId)) : dispatch(unselectTest(testId));
    };

    if (isLoading) return <Preloader />;

    // LAYOUT
    return (
        <div className={`flex__column ${style.testsAssign}`}>
            <BreadCrumbs links={links} />
            <div className={`flex ${style.candidateTests}`}>
                <div className={`flex__column ${style.testsAssign}`}>
                    <NavigationBar links={linksNavigation} buttons={[]} />
                    <AssignTestsContainer />
                </div>
                <div className={`flex__column ${style.testsList}`}>
                    <h2>Выберите или создайте тест</h2>
                    <div className={`flex ${style.testsListButtons}`}>
                        <ChoiceTab
                            isActive={isMySet}
                            text="Мои наборы тестов"
                            onClick={() => {
                                setIsMySet(true);
                                setIsCreateTests(false);
                            }}
                        />
                        <ChoiceTab
                            isActive={isCreateTests}
                            text="Создать набор тестов"
                            onClick={() => {
                                setIsCreateTests(true);
                                setIsMySet(false);
                            }}
                        />
                    </div>
                    {
                        isMySet && (
                            <div className={`flex__column ${style.testsBlock}`}>
                                {
                                    testsCategories.length && testsCategories.map((category, index) => (
                                        <div key={index}>
                                            <h3>{category.type[0].toUpperCase() + category.type.slice(1)}</h3>
                                            <div className={`flex ${style.testsBlockTests}`}>
                                                {
                                                    category.tests.map((test, index) => {
                                                        const isChecked = testsState?.selectedTests?.some(selectedTest => selectedTest === test.id);
                                                        return (
                                                            <TestCheckbox
                                                                key={index}
                                                                index={index}
                                                                testName={test.name}
                                                                numberOfQuestions={test.questions.length}
                                                                time={formatDuration(test.time_allocated)}
                                                                isChecked={isChecked}
                                                                onClick={onCheckboxClick(isChecked, test.id)}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                    {
                        isCreateTests && (
                            <TestsCreate isFromAssignTestsPage={true} />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

// EXPORT
export default TestsAssign;