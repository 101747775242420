import { Form, Field } from 'react-final-form';
import { loginValidate } from "../../utils/formValidators/loginValidate";
import style from "./Login.module.css";
import { useNavigate } from 'react-router';
import ButtonNew from '../../components/UI/ButtonNew/ButtonNew';

// LOGIN FORM
const LoginForm = ({login}) => {

    const navigate = useNavigate();

    // HANDLE SUBMIT
    const onSubmit = function (values) {
        login(values.email, values.password, navigate);
    };


    // LAYOUT
    return (
        <Form
            onSubmit={onSubmit}
            validate={loginValidate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <div className={`flex__column input__label ${style.flex__column_margin}`}>
                        <label className="text__label">Email</label>
                        <Field
                            name="email"
                            render={({ input }) => (
                                <input
                                    {...input}
                                    type="email"
                                    placeholder="abc@abc.com"
                                    className="input"
                                />
                            )}
                        />
                        <div style={{ color: 'red' }}>
                            <Field name="email" subscription={{ touched: true, error: true }}>
                                {({ meta }) => (meta.touched && meta.error ? <span>{meta.error}</span> : null)}
                            </Field>
                        </div>
                    </div>
                    <div className={`flex__column input__label ${style.input__label_margin}`}>
                        <label className="text__label">Пароль</label>
                        <Field
                            name="password"
                            render={({ input }) => (
                                <input
                                    {...input}
                                    type="password"
                                    placeholder="**********"
                                    className="input"
                                />
                            )}
                        />
                        <div style={{ color: 'red' }}>
                            <Field name="password" subscription={{ touched: true, error: true }}>
                                {({ meta }) => (meta.touched && meta.error ? <span>{meta.error}</span> : null)}
                            </Field>
                        </div>
                    </div>
                    <ButtonNew text="Войти" className="button_gradient" type="submit" />
                </form>
            )}
        />
    )
}

// LOGIN PAGE
const Login = ({login}) => {
    return (
        <div className={style.login}>
            <div className={`flex__2 ${style.loginWrapper}`}>
                <div className="flex__column">
                    <h1 className={style.loginHeading}>Вход</h1>
                    <LoginForm login={login} />
                </div>
                {/* <img alt="logo" src={logo} className={style.logo} /> */}
            </div>
        </div>
    );
};

export default Login;