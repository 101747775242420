import TestCheckbox from "../TestCheckbox/TestCheckbox";
import style from "./TestBlock.module.css";

// TEST BLOCK COMPONENT
const TestBlock = ({question, currentQuestionNumber, totalQuestions}) => {

    // LAYOUT
    return (
        <div className={`flex__column ${style.testBlock}`}>
            <h3 className={style.testBlockHeading}>{question?.description}</h3>
            <div className={`flex__column ${style.testBlockOptions}`}>
                {question?.choices
                .map((choice, index) => <TestCheckbox 
                                            option={choice} 
                                            question={question?.id} 
                                            index={index} 
                                            questionNumber={currentQuestionNumber} 
                                        />)}
            </div>
            <div className={`flex ${style.testBlockNumber}`}>
                <div className={style.testBlockNumberCurrent}>
                    <p className={style.testBlockNumberCurrentText}>{currentQuestionNumber}</p>
                </div>
                <p className={style.testBlockNumberTotal}>из {totalQuestions}</p>
            </div>
        </div>
    );
};

// EXPORT 
export default TestBlock;