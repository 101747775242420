
// AFTER SUCCESSFUL LOGIN
export const handleLoginSuccess = (token, refresh) => {

    // PLACING TOKENS INTO LOCAL STORAGE
    localStorage.setItem("token", token);
    localStorage.setItem("refresh", refresh);
};

// REFRESH TOKEN
export const refreshToken = (access) => {

    // PLACE ACCESS TOKEN TO STORAGE
    localStorage.setItem("token", access);
};

// REMOVE TOKEN
export const removeToken = () => {

    // SETTING ACCESS AND REFRESH TOKENS TO ""
    localStorage.setItem("token", "");
    localStorage.setItem("refresh", "");
};