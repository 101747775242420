import { candidatesAPI } from "../api/api";
import { errorToast, successToast } from "../utils/toasts/toasts";
import { createNotification } from "./notificationsReducer";
import { stopLoadingActionCreator } from "./preloaderReducer";
import { clearSelectedTests } from "./testsReducer";

// ACTION TYPES
const ADD_CANDIDATE = "ADD_CANDIDATE";
const SET_CANDIDATE_EMAIL = "SET_CANDIDATE_EMAIL";
const SET_CANDIDATE_NAME = "SET_CANDIDATE_NAME";
const REMOVE_CANDIDATE = "REMOVE_CANDIDATE";
const RESET_CANDIDATES = "RESET_CANDIDATES";

// INITIAL STATE
const initialState = {
    candidates: [
        {name: "", email: "",},
    ], // [{name: "John", email: "john@gmail.com"}, ...]
};

// CANDIDATES REDUCER
export const candidatesReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_CANDIDATE:
            return { ...state, candidates: [...state.candidates, action.candidate] };
        case SET_CANDIDATE_EMAIL:
            return { ...state, candidates: state.candidates.map((candidate, index) => {
                if(index === action.index) {
                    return { ...candidate, email: action.email};
                }
                return candidate;
            })}
        case SET_CANDIDATE_NAME:
            return { ...state, candidates: state.candidates.map((candidate, index) => {
                if(index === action.index) {
                    return { ...candidate, name: action.name};
                }
                return candidate;
            })}
        case REMOVE_CANDIDATE:
            return { ...state, candidates: state.candidates.filter((candidate, index) => index !== action.index) };
        case RESET_CANDIDATES:
            return { ...state, candidates: [{name: "", email: "",},]};
        default:
            return state;
    }
};

// ACTION CREATORS
export const addCandidateActionCreator = () => ({type: ADD_CANDIDATE, candidate: {name: "", email: ""}});
export const setCandidateEmailActionCreator = (index, email) => ({type: SET_CANDIDATE_EMAIL, index, email});
export const setCandidateNameActionCreator = (index, name) => ({type: SET_CANDIDATE_NAME, index, name});
export const removeCandidateActionCreator = (index) => ({type: REMOVE_CANDIDATE, index});
export const resetCandidatesActionCreator = () => ({type: RESET_CANDIDATES});

// THUNKS
export const updateCandidate = (candidateId, accessed_tests) => dispatch => {
    candidatesAPI.updateCandidate(candidateId, accessed_tests)
    .then(response => {
        if(response.status === 200) {
            dispatch(stopLoadingActionCreator());
            accessed_tests.forEach(testId => dispatch(createNotification("2025-08-23T16:00:00", null, candidateId, testId, response.data.company)));
            successToast("Тесты были отправлены на почту!");
            dispatch(resetCandidatesActionCreator());
            dispatch(clearSelectedTests());
        }
    })
};
export const createCandidate = (name, email, company, accessed_tests) => dispatch => {
    candidatesAPI.createCandidate(name, email, company, accessed_tests)
    .then(response => {
        if(response.status === 201) {
            dispatch(stopLoadingActionCreator());
            accessed_tests.forEach(testId => dispatch(createNotification("2025-08-23T16:00:00", null, response.data.id, testId, response.data.company)));
            successToast("Тесты были отправлены на почту!");
            dispatch(resetCandidatesActionCreator());
            dispatch(clearSelectedTests());
        }
    })
    .catch(error => {
        if(error.response.data?.email?.[0] === "Enter a valid email address.") {
            errorToast("Введите корректный email");
            return;
        }
        if(error.response.data?.email) {
            const pattern = /id (\d+)/;
            const match = pattern.exec(error.response.data.email[0]);
            const id = match[1];
            if(id) {
                dispatch(updateCandidate(+id, accessed_tests));
            } else {
                dispatch(stopLoadingActionCreator());
                errorToast(error.response.data.email[0]);
            }
        }
    })
};