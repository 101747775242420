import style from "./Preloader.module.css";

// PRELOADER COMPONENT
const Preloader = () => {

    // LAYOUT
    return (
        <div>
            <div className={style.ldsHourglass}></div>
            <div className={style.overlay}></div>
        </div> 
    );
};

// EXPORT
export default Preloader;