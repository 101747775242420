
// SET IMAGE FUNCTION
export const setImage = (file, elementId) => {
    if (file) {
        const reader = new FileReader();

        reader.onload = function(event) {
            const logoPreview = document.getElementById(elementId);
            logoPreview.src = event.target.result;
            logoPreview.style.display = 'block';
        };

        reader.readAsDataURL(file);
    }
};