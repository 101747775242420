import { rolesAPI } from "../../api/api";

// ACTION TYPES
export const CREATE_ROLE = "CREATE_ROLE";
export const SET_ROLES = "SET_ROLES";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

// INITIAL STATE
const initialState = {
    roles: [],
};

// ROLES REDUCER
export const rolesReducer = (state = initialState, action) => {
    switch(action.type) {
        case CREATE_ROLE:
            return {
                ...state,
                roles: [...state.roles, action.role]
            };
        case SET_ROLES:
            return {
                ...state,
                roles: action.roles
            };
        case UPDATE_ROLE:
            return {
                ...state,
                roles: state.roles.map(role => {
                  if (role.id === action.role.id) {
                    return action.role; // Replace the matching role with the updated role
                  }
                  return role; // Keep other roles unchanged
                })
              };
        case DELETE_ROLE:
            const removedRole = state.roles.find(role => role.id === action.role.id);
            const indexOfRemovedRole = state.roles.indexOf(removedRole);
            return {...state, 
                    roles: state.roles.splice(indexOfRemovedRole, 1),
            };
        default:
            return state;
    };
};

// ACTION CREATORS
export const createRoleActionCreator = (role) => ({type: CREATE_ROLE, role});
export const setRolesActionCreator = (roles) => ({type: SET_ROLES, roles});
export const updateRoleActionCreator = (role) => ({type: UPDATE_ROLE, role});
export const deleteRoleActionCreator = (role) => ({type: DELETE_ROLE, role});

// THUNKS
export const createRole = (title, permissions) => dispatch => {
    rolesAPI.createRole(title, permissions)
    .then(response => {
        dispatch(createRoleActionCreator(response.data));
    })
};
export const getRoles = () => dispatch => {
    rolesAPI.getRoles()
    .then(response => {
        dispatch(setRolesActionCreator(response.data));
    });
}
export const updateRole = (title, permissions, id) => dispatch => {
    rolesAPI.updateRole(title, permissions, id)
    .then(response => {
        dispatch(updateRoleActionCreator(response.data));
    });
}
export const deleteRole = (id) => dispatch => {
    rolesAPI.deleteRole(id)
    .then(response => {
        dispatch(deleteRoleActionCreator(response.data));
    });
}