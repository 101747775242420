import style from "./ScatterChart.module.css";
import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getRandomInteger } from "../../../utils/getRandomInteger/getRandomInteger";
import sampleLogo from "../../../assets/images/google-logo.svg.webp";


// SCATTER CHART COMPONENT FOR DOTTED GRAPH
const ScatterChartComponent = ({data}) => {

    const CustomDot = (props) => {
        const { cx, cy, payload } = props;

        const logoUrl = payload.logoUrl;

        const logoSize = 24;
        return (
            <image 
                className={style.dotImage} 
                href={logoUrl} 
                x={cx - logoSize / 2} 
                y={cy - logoSize / 2} 
                width={logoSize} 
                height={logoSize} 
            />
        );
    };

    // LAYOUT
    return (
        <ResponsiveContainer width="100%" height={292}>
            <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                <CartesianGrid stroke="#F4F4F4" strokeArray="4 4" />
                <YAxis type="number" dataKey="y" name="number" stroke="#18181A" />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Scatter data={data} fill="red" shape={<CustomDot />} />
            </ScatterChart>
        </ResponsiveContainer>
    );
};

// EXPORT
export default ScatterChartComponent;