export const convertDateFromHyphen = function(inputDate) {

    // SPLIT DATE OF FORMAT YYYY-MM-DD TO AN ARRAY
    const splittedDate = inputDate.split("-");

    // DETERMINING YEAR, MONTH, DAY
    const [year, month, day] = splittedDate;

    // RETURNING DATE IN FORMAT
    return `${day}.${month}.${year}`;
};