import { connect } from "react-redux";
import Registration from "./Registration";
import { register } from "../../redux/authReducer";

// MAP STATE TO PROPS
const mapStateToProps = (state) => {
    return {
        role: state.auth.role,
        isAuth: state.auth.isAuth,
    };
};

// CONTAINER FOR REGISTRATION COMPONENT
export const RegistrationContainer = connect(mapStateToProps, {register})(Registration); 