import style from "./AssignTests.module.css";
import { Field, Form } from "react-final-form";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCandidateActionCreator, createCandidate, removeCandidateActionCreator, setCandidateEmailActionCreator, setCandidateNameActionCreator } from "../../../redux/candidatesReducer";
import { errorToast } from "../../../utils/toasts/toasts";
import { hasDuplicateEmails } from "../../../utils/checkDuplicateEmails/checkDuplicateEmails";
import { startComponentLoadingActionCreator, startLoadingActionCreator } from "../../../redux/preloaderReducer";
import Preloader from "../../UI/Preloader/Preloader";
import ChoiceTab from "../ChoiceTab/ChoiceTab";
import Candidate from "./Candidate/Candidate";
import { formatDuration } from "../../../utils/durationConverter/durationConverter";
import ButtonNew from "../../UI/ButtonNew/ButtonNew";
import SearchInput from "../../UI/SearchInput/SearchInput";
import avatar from "../../../assets/images/avatar.png";
import { getCompanies, removeChosenCompanyActionCreator, setChosenCompaniesActionCreator, setSearchTextActionCreator, setSearchedCompaniesActionCreator } from "../../../redux/companiesReducer";
import { getUserGroups, getUserGroupsOfCompany, removeChosenUsergroupActionCreator, removeUsergroupByCompanyActionCreator, setChosenUsergroupsActionCreator, setGroupsSearchTextActionCreator, setSearchedUsergroupsActionCreator } from "../../../redux/userGroupsReducer";
import Checkbox from "../Checkbox/Checkbox";
import { assignTests, getUsersOfUsergroup, removeChosenUserActionCreator, removeChosenUsersByGroupActionCreator, removeUserByGroupActionCreator, resetChosenUsersActionCreator, setAllUsersChosenActionCreator, setChosenUsersActionCreator } from "../../../redux/usersReducer";

// CANDIDATE FORM
const CandidateForm = ({ candidates }) => {

    // USE DISPATCH
    const dispatch = useDispatch();

    // USE SELECTOR
    const candidatesState = useSelector(state => state.candidates);

    // USE STATE
    const [numberOfCandidates, setNumberOfCandidates] = useState(candidatesState.candidates.length);

    // HANDLE SUBMIT
    const onSubmit = function (values) {
        console.log(values);
    };

    // ADD CANDIDATE CALLBACK
    const addCandidate = function () {
        setNumberOfCandidates(prev => prev + 1);
        dispatch(addCandidateActionCreator());
    };

    // REMOVE CANDIDATE CALLBACK
    const removeCandidate = (index) => () => {
        setNumberOfCandidates(prev => prev - 1);
        dispatch(removeCandidateActionCreator(index));
    };

    // SETTING CANDIDATE EMAIL
    const onEmailChange = (index) => e => {
        dispatch(setCandidateEmailActionCreator(index, e.target.value));
    };

    // SETTING CANDIDATE NAME
    const onNameChange = (index) => e => {
        dispatch(setCandidateNameActionCreator(index, e.target.value));
    };


    // LAYOUT
    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
                <form className={style.candidates} onSubmit={handleSubmit}>
                    <div>
                        <div className={`flex__column ${style.emails}`}>
                            {
                                Array.from({ length: numberOfCandidates }).map((_, index) => (
                                    <Candidate
                                        key={index}
                                        number={index + 1}
                                        onEmailChange={onEmailChange(index)}
                                        onNameChange={onNameChange(index)}
                                        onCandidateRemove={removeCandidate(index)}
                                        onCandidateAdd={addCandidate}
                                        emailValue={candidates[index]?.email}
                                        nameValue={candidates[index]?.name}
                                        isLast={index === numberOfCandidates - 1}
                                        index={index}
                                    />
                                ))
                            }
                            <div className={`flex ${style.emailsAdd}`}>
                                <ButtonNew 
                                    text="Добавить"
                                    className="button_gradient"
                                    icon="icon-add-square"
                                    onClick={addCandidate}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            )}
        />
    )
};

// ASSIGN TESTS COMPONENT
const AssignTests = ({ selectedTests, tests, clearSelectedTests, removeSelectedTest }) => {

    // USE STATE
    const [isSendToCandidate, setIsSendToCandidate] = useState(true);

    // USE DISPATCH
    const dispatch = useDispatch();

    // USE SELECTOR
    const candidatesState = useSelector(state => state.candidates);
    const { companyId } = useSelector(state => state.auth);
    const { isLoading, isComponentLoading } = useSelector(state => state.preloader);
    const { companies, searchedCompanies, searchText: companySearchText, chosenCompanies } = useSelector(state => state.companies);
    const { userGroups, searchedUsergroups, searchText: usergroupSearchText, chosenUserGroups } = useSelector(state => state.userGroups);
    const { users, chosenUsers } = useSelector(state => state.users);

    // LOOKING FOR TESTS OBJECTS THAT MATCH WITH SELECTED TESTS
    const testsObjectsArray = selectedTests?.map(test => tests?.find(testObject => testObject.id === test));

    // COUNTING NUMBER OF QUESTIONS IN ALL SELECTED TESTS
    let numberOfQuestions = 0;
    testsObjectsArray.forEach(test => {
        numberOfQuestions += test?.questions?.length;
    });

    // CALLBACKS
    const clearTests = function () {
        dispatch(clearSelectedTests());
    };
    const removeTest = (testId) => () => {
        dispatch(removeSelectedTest(testId));
    };
    const getCompaniesFunction = () => {
        dispatch(getCompanies());
    };
    const getUsergroupsFunction = () => {
        if (!chosenCompanies.length) {
            errorToast("Сначала выберите компанию");
            return;
        };
        chosenCompanies.forEach(company => {
            dispatch(getUserGroupsOfCompany(company.id));
        });
    };
    const onCompaniesSearchChange = (event) => {
        dispatch(setSearchTextActionCreator(event.target.value));
        dispatch(setSearchedCompaniesActionCreator());
    };
    const onUsergroupsSearchChange = (event) => {
        dispatch(setGroupsSearchTextActionCreator(event.target.value));
        dispatch(setSearchedUsergroupsActionCreator());
    };
    const onCompanyClick = (company) => () => {
        dispatch(setChosenCompaniesActionCreator(company));
    };
    const onUsergroupClick = (usergroup) => () => {
        dispatch(startComponentLoadingActionCreator());
        dispatch(getUsersOfUsergroup(usergroup.id));
        dispatch(setChosenUsergroupsActionCreator(usergroup));
    };
    const removeCompany = (companyId) => () => {
        dispatch(removeChosenCompanyActionCreator(companyId));
        dispatch(removeUsergroupByCompanyActionCreator(companyId));
    };
    const removeUsergroup = (groupId) => () => {
        dispatch(removeChosenUsergroupActionCreator(groupId));
        dispatch(removeUserByGroupActionCreator(groupId));
        dispatch(removeChosenUsersByGroupActionCreator(groupId));
    };
    const onUserCheck = user => () => {
        dispatch(setChosenUsersActionCreator(user));
    };
    const onUserUncheck = userId => () => {
        dispatch(removeChosenUserActionCreator(userId));
    };
    const chooseAllEmployees = () => {
        dispatch(setAllUsersChosenActionCreator());
    };
    const deselectAllEmployees = () => {
        dispatch(resetChosenUsersActionCreator());
    };

    // ON DATA SUBMIT
    const sendTests = function () {
        
        // CHECKING IF TESTS WERE CHOSEN
        if(!selectedTests?.length) {
            errorToast("Вы не выбрали тест");
            return;
        }
        
        if(isSendToCandidate) {
            if (candidatesState?.candidates?.some(candidate => candidate.name === "" || candidate.email === "")) {
                errorToast("Данные одного из кандидатов не заполнены");
                return;
            }
            if (hasDuplicateEmails(candidatesState?.candidates)) {
                errorToast("Вы указали 2 или больше одинаковых почт");
                return;
            }
            candidatesState?.candidates?.forEach(candidate => {
                dispatch(startLoadingActionCreator());
                dispatch(createCandidate(candidate.name, candidate.email, companyId || 1, selectedTests));
            });
        } else {
            if(!chosenUsers.length) {
                errorToast("Вы не выбрали сотрудника");
                return;
            }
            chosenUsers?.forEach(user => {
                dispatch(startLoadingActionCreator());
                dispatch(assignTests([...user.accessed_tests, ...selectedTests], user.id, user.name));
            })
        }
    };

    // LAYOUT
    return (
        <div className={`flex__column ${style.assignTestsBox}`}>
            {
                isLoading && <Preloader />
            }
            <div className={`flex ${style.assignTestsBoxTabs}`}>
                <ChoiceTab
                    isActive={isSendToCandidate}
                    text="Кандидат"
                    onClick={() => setIsSendToCandidate(true)}
                />
                <ChoiceTab
                    isActive={!isSendToCandidate}
                    text="Сотрудник"
                    onClick={() => setIsSendToCandidate(false)}
                />
            </div>
            {
                isSendToCandidate && (
                    <div className={`flex__column ${style.assignTestsBoxCandidates}`}>
                        <div className={`flex ${style.assignTestsBoxCandidatesHeading}`}>
                            <i className={`icon-profile-2user ${style.assignTestsBoxCandidatesHeadingIcon}`} />
                            <h2>Список кандидатов</h2>
                        </div>
                        <CandidateForm candidates={candidatesState.candidates} />
                    </div>
                )
            }
            {
                !isSendToCandidate && (
                    <div className={`flex__column ${style.employee}`}>
                        <div className={`flex__column ${style.employeeBlock}`}>
                            <div className={`flex ${style.employeeHeading}`}>
                                <i className={`icon-buildings-2 ${style.employeeHeadingIcon}`} />
                                <h2>Выберите компанию</h2>
                            </div>
                            <SearchInput
                                isDropdown={true}
                                headingText="Все компании"
                                onFocusEvent={getCompaniesFunction}
                                items={companies}
                                searchedItems={searchedCompanies}
                                onSearchInputChange={onCompaniesSearchChange}
                                searchText={companySearchText}
                                onItemClick={onCompanyClick}
                                id="companies"
                            />
                            <div className={`flex__column ${style.items}`}>
                                {
                                    chosenCompanies?.length
                                        ? chosenCompanies.map((company, index) => (
                                            <div className={`flex__2 ${style.item}`} key={index}>
                                                <div className={`flex ${style.naming}`}>
                                                    <p className={style.itemName}>{company?.name}</p>
                                                </div>
                                                <i className={`icon-close-circle ${style.testCrossIcon}`} onClick={removeCompany(company?.id)} />
                                            </div>
                                        ))
                                        :
                                        <p className={style.itemsText}>Ничего не выбрано</p>
                                }
                            </div>
                        </div>
                        <div className={`flex__column ${style.employeeBlock}`}>
                            <div className={`flex ${style.employeeHeading}`}>
                                <i className={`icon-profile-2user ${style.employeeHeadingIcon}`} />
                                <h2>Выберите отдел</h2>
                            </div>
                            <SearchInput
                                isDropdown={true}
                                headingText="Все отделы"
                                onFocusEvent={getUsergroupsFunction}
                                items={userGroups}
                                searchedItems={searchedUsergroups}
                                onSearchInputChange={onUsergroupsSearchChange}
                                searchText={usergroupSearchText}
                                onItemClick={onUsergroupClick}
                                id="usergroups"
                            />
                            <div className={`flex__column ${style.items}`}>
                                {
                                    chosenUserGroups?.length
                                        ? chosenUserGroups.map((group, index) => (
                                            <div className={`flex__2 ${style.item}`} key={index}>
                                                <div className={`flex ${style.naming}`}>
                                                    <p className={style.itemName}>{group?.name}</p>
                                                </div>
                                                <i className={`icon-close-circle ${style.testCrossIcon}`} onClick={removeUsergroup(group.id)} />
                                            </div>
                                        ))
                                        :
                                        <p className={style.itemsText}>Ничего не выбрано</p>
                                }
                            </div>
                        </div>
                        <div className={`flex__column ${style.employeeBlock}`}>
                            <div className={`flex ${style.employeeHeading}`}>
                                <i className={`icon-profile-2user ${style.employeeHeadingIcon}`} />
                                <h2>Выберите сотрудников</h2>
                            </div>
                            <div className={`flex__column ${style.items} ${style.itemsUsers}`}>
                                {
                                    isComponentLoading
                                        ? <div className="preloader preloader_users"><Preloader /></div>
                                        : users.length ? users.map((user, index) => (
                                            <div className={`flex ${style.itemsItem}`} key={index}>
                                                <div className={`flex ${style.itemsItemName}`}>
                                                    <img src={avatar} alt="avatar" className={style.itemsItemNameImage} />
                                                    <p className={style.itemsItemNameText}>{user.name}</p>
                                                </div>
                                                <Checkbox 
                                                    onCheck={onUserCheck(user)} 
                                                    onUncheck={onUserUncheck(user?.id)} 
                                                    isChecked={chosenUsers.some(chosenUser => chosenUser.id === user.id)} 
                                                />
                                            </div>
                                        )) : null
                                }
                            </div>
                            <div className={`flex__column ${style.items}`}>
                                {
                                    chosenUsers?.length
                                        ? chosenUsers.map((user, index) => (
                                            <div className={`flex__2 ${style.item}`} key={index}>
                                                <div className={`flex ${style.naming}`}>
                                                    <p className={style.itemName}>{user?.name}</p>
                                                </div>
                                                <i className={`icon-close-circle ${style.testCrossIcon}`} onClick={onUserUncheck(user?.id)} />
                                            </div>
                                        ))
                                        :
                                        <p className={style.itemsText}>Никто не выбран</p>
                                }
                            </div>
                            <div className={`flex ${style.employeeCheckbox}`}>
                                <Checkbox 
                                    onCheck={chooseAllEmployees}
                                    onUncheck={deselectAllEmployees}
                                    isChecked={users.length === chosenUsers.length}
                                    className="primary"
                                />
                                <p className={style.employeeCheckboxText}>Выбрать всех сотрудников отдела</p>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className={`flex__column ${style.assignTestsBoxTests}`}>
                <div className={`flex ${style.assignTestsBoxTestsHeading}`}>
                    <i className={`icon-document-text ${style.assignTestsBoxTestsHeadingIcon}`} />
                    <h2>Тесты</h2>
                </div>
                <div className={`flex__column ${style.items}`}>
                    {
                        testsObjectsArray?.length
                            ? testsObjectsArray.map((test, index) => (
                                <div className={`flex__2 ${style.item}`} key={index}>
                                    <div className={`flex ${style.naming}`}>
                                        <p className={style.itemName}>{test?.name}</p>
                                    </div>
                                    <i className={`icon-close-circle ${style.testCrossIcon}`} onClick={removeTest(test?.id)} />
                                </div>
                            ))
                            :
                            <p className={style.itemsText}>Ничего не выбрано</p>
                    }
                </div>
            </div>
            <div className={`flex__column ${style.assignTestsBoxDuration}`}>
                <div className={`flex ${style.assignTestsBoxDurationHeading}`}>
                    <i className={`icon-timer ${style.assignTestsBoxDurationHeadingIcon}`} />
                    <h2>Время</h2>
                </div>
                <div className={`flex__column ${style.assignTestsBoxDurationData}`}>
                    <div className={`flex ${style.assignTestsBoxDurationDataTests}`}>
                        <i className={`icon-document-text ${style.assignTestsBoxDurationDataIcon}`} />
                        <h3>{testsObjectsArray.length} тестов</h3>
                    </div>
                    <div className={`flex ${style.assignTestsBoxDurationDataTime}`}>
                        <i className={`icon-clock ${style.assignTestsBoxDurationDataIcon}`} />
                        <h3>
                            {formatDuration(testsObjectsArray
                                .map(test => test.time_allocated)
                                .filter(time_allocated => time_allocated)
                                .reduce((acc, current) => acc + current, 0))}
                        </h3>
                    </div>
                </div>
            </div>
            <div className={`flex__column ${style.assignTestsBoxButtons}`}>
                <div className={style.assignTestsBoxButtonsButton}>
                    <ButtonNew
                        text="Очистить"
                        className="button_transparent"
                        onClick={clearTests}
                    />
                </div>
                <div className={style.assignTestsBoxButtonsButton}>
                    <ButtonNew
                        text="Отправить"
                        className="button_gradient"
                        onClick={sendTests}
                    />
                </div>
            </div>
        </div>
    );
};

//EXPORT
export default AssignTests;