import { companiesAPI } from "../api/api";
import { errorToast, successToast } from "../utils/toasts/toasts";
import { stopComponentLoadingActionCreator, stopLoadingActionCreator } from "./preloaderReducer";

// ACTION TYPES
const SET_COMPANIES = "SET_COMPANIES";
const SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY";
const SET_COMPANIES_TOTAL = "SET_COMPANIES_TOTAL";
const DELETE_COMPANY = "DELETE_COMPANY";
const REMOVE_USER = "REMOVE_USER";
const UPDATE_COMPANY = "UPDATE_COMPANY";
const SET_IS_TOGGLE_ON = "SET_IS_TOGGLE_ON";
const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
const SET_SEARCHED_COMPANIES = "SET_SEARCHED_COMPANIES";
const SET_CHOSEN_COMPANIES = "SET_CHOSEN_COMPANIES";
const REMOVE_CHOSEN_COMPANY = "REMOVE_CHOSEN_COMPANY";

// INITIAL STATE
const initialState = {
    companies: [],
    currentCompany: null,
    totalCompanies: null,
    isToggleOn: false,
    searchText: "",
    searchedCompanies: [],
    chosenCompanies: [], // [{company_object1}, {...}]
};

// COMPANIES REDUCER
export const companiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANIES:
            return { ...state, companies: action.companies };
        case SET_CURRENT_COMPANY:
            return { ...state, currentCompany: action.company };
        case SET_COMPANIES_TOTAL:
            return { ...state, totalCompanies: action.companiesTotal };
        case DELETE_COMPANY:
            return { ...state, companies: state.companies.filter(company => company.id !== action.companyId) };
        case REMOVE_USER:
            return {
                ...state,
                currentCompany: { ...state.currentCompany, users: state?.currentCompany?.users?.filter(user => user.id !== action.userId) }
            };
        case SET_IS_TOGGLE_ON:
            return { ...state, isToggleOn: !state.isToggleOn };
        case SET_SEARCH_TEXT:
            return { ...state, searchText: action.text };
        case SET_SEARCHED_COMPANIES:
            return {
                ...state,
                searchedCompanies: state.companies.filter(company => company.name.toLowerCase().includes(state.searchText.toLowerCase())),
            };
        case SET_CHOSEN_COMPANIES:
            return {
                ...state,
                chosenCompanies: [...state.chosenCompanies,
                ... state.chosenCompanies.some(company => company.id === action.company.id) ? [] : [action.company]
                ],
            };
        case REMOVE_CHOSEN_COMPANY:
            return {
                ...state,
                chosenCompanies: state.chosenCompanies.filter(company => company.id !== action.companyId),
            }
        default: {
            return state;
        }
    };
};

// ACTION CREATORS
export const setCompaniesActionCreator = (companies) => ({ type: SET_COMPANIES, companies });
export const setCurrentCompanyActionCreator = (company) => ({ type: SET_CURRENT_COMPANY, company });
export const setCompaniesTotalActionCreator = (companiesTotal) => ({ type: SET_COMPANIES_TOTAL, companiesTotal });
export const deleteCompanyActionCreator = (companyId) => ({ type: DELETE_COMPANY, companyId });
export const removeUserActionCreator = (userId) => ({ type: REMOVE_USER, userId });
export const updateCompanyActionCreator = (company) => ({ type: UPDATE_COMPANY, company });
export const setIsToggleOn = () => ({ type: SET_IS_TOGGLE_ON });
export const setSearchTextActionCreator = (text) => ({ type: SET_SEARCH_TEXT, text });
export const setSearchedCompaniesActionCreator = () => ({ type: SET_SEARCHED_COMPANIES });
export const setChosenCompaniesActionCreator = (company) => ({ type: SET_CHOSEN_COMPANIES, company });
export const removeChosenCompanyActionCreator = (companyId) => ({type: REMOVE_CHOSEN_COMPANY, companyId});

// THUNKS
export const getCompanies = () => dispatch => {
    companiesAPI.getCompanies()
        .then(response => {
            if (response.status === 200) {
                dispatch(stopLoadingActionCreator());
                dispatch(stopComponentLoadingActionCreator());
                dispatch(setCompaniesActionCreator(response.data));
                dispatch(setCompaniesTotalActionCreator(response.data.length));
            }
        })
        .catch(error => {
            dispatch(stopComponentLoadingActionCreator());
            errorToast("У вас нет доступа");
        })
};
export const getCompany = companyId => dispatch => {
    return companiesAPI.getCompany(companyId)
        .then(response => {
            if (response.status === 200) {
                dispatch(stopLoadingActionCreator());
                dispatch(setCurrentCompanyActionCreator(response.data));
                return response;
            }
        })
        .catch(error => {
            errorToast("У вас нет доступа");
        })
};
export const createCompany = (formData) => dispatch => {
    return companiesAPI.createCompany(formData)
        .then(response => {
            if (response.status === 201) {
                successToast("Компания успешно создана!");
            }
        })
        .catch(error => {
            if(error.response.data?.admin) {
                errorToast("Данный администратор уже состоит в другой компании");
            }
        })
};
export const updateCompany = (formData, companyId) => dispatch => {
    companiesAPI.updateCompany(formData, companyId)
        .then(response => {
            if (response.status === 200) {
                successToast("Компания успешно обновлена!");
                dispatch(getCompany(companyId));
            }
        })
        .catch(error => {
            errorToast(error.response.data.logo[0]);
        })
};
export const deleteCompany = (companyId) => dispatch => {
    return companiesAPI.deleteCompany(companyId)
        .then(response => {
            successToast("Компания успешно удалена!");
            dispatch(deleteCompanyActionCreator(companyId));
        })
};
export const blockCompany = (inactive_till, companyId) => dispatch => {
    companiesAPI.blockCompany(inactive_till, companyId)
        .then(response => {
            if(response.status === 200) {
                successToast("Компания заблокирована на год");
                dispatch(getCompany(companyId));
            }
        })
        .catch(error => {
            errorToast("Не получилось заблокировать компанию");
        })
};
export const unblockCompany = (active_till, companyId) => dispatch => {
    companiesAPI.unblockCompany(active_till, companyId)
        .then(response => {
            if(response.status === 200) {
                successToast("Компания разблокирована");
                dispatch(getCompany(companyId));
            }
        })
        .catch(error => {
            errorToast("Не получилось разблокировать компанию");
        })
};