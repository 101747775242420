export const loginValidate = (values) => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Требуется почта!';
    }

    if (!values.password) {
        errors.password = 'Требуется пароль!';
    }

    return errors;
};