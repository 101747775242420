import { testsAPI } from "../api/api";
import { timeToMinutes } from "../utils/timeToMinutes/timeToMinutes";
import { errorToast, successToast } from "../utils/toasts/toasts";
import { stopLoadingActionCreator } from "./preloaderReducer";

// ACTION TYPES
// READ
export const SET_TESTS = "SET_TESTS";
export const SET_CURRENT_TEST = "SET_CURRENT_TEST";

// CREATE
export const CREATE_TEST = "CREATE_TEST";
export const CREATE_QUESTION = "CREATE_QUESTION";
export const CREATE_CHOICE = "CREATE_CHOICE";
export const DELETE_TEST = "DELETE_TEST";

export const SELECT_TEST = "SELECT_TEST";
export const CLEAR_SELECTED_TESTS = "CLEAR_SELECTED_TESTS";
export const REMOVE_SELECTED_TEST = "REMOVE_SELECTED_TEST";

// INITIAL STATE
const intialState = {
    tests: [],
    selectedTests: [],
    currentTest: null,
};

// TESTS REDUCER
export const testsReducer = (state = intialState, action) => {
    switch (action.type) {

        case SET_TESTS:
            return {
                ...state,
                tests: action.tests,
            };
        case SET_CURRENT_TEST:
            return {
                ...state,
                currentTest: action.test,
            };

        case CREATE_TEST:
            return { ...state, tests: [...state.tests, action.test] };
        case CREATE_QUESTION: {
            const newTests = state.tests.map(test => {
                if (test.id === action.question.test) {
                    let questions;
                    if(test?.questions) {
                        questions = test.questions;
                    } else {
                        questions = [];
                    }
                    return { ...test, questions: [ ...questions, action.question] };
                }
                return test;
            });
            return { ...state, tests: newTests };
        }
        case CREATE_CHOICE: {
            const newTests = state.tests.map(test => {
                if (test.id === action.test) {
                    return {
                        ...test,
                        questions: test.questions.map(question => {
                            if (question.id === action.choice.question) {
                                let choices;
                                if(question?.choices) {
                                    choices = question.choices;
                                } else {
                                    choices = [];
                                }
                                return {
                                    ...question,
                                    choices: [...choices, action.choice]
                                };
                            }
                            return question;
                        })
                    };
                }
                return test;
            });
            return { ...state, tests: newTests };
        }
        case DELETE_TEST:
            return { ...state, tests: state.tests.filter(test => test.id !== action.testId) };
        case SELECT_TEST:
            return { ...state, selectedTests: [...state.selectedTests, action.testId] };
        case CLEAR_SELECTED_TESTS:
            return { ...state, selectedTests: [] };
        case REMOVE_SELECTED_TEST:
            return { ...state, selectedTests: state.selectedTests.filter(test => test !== action.testId) };
        default:
            return state;
    }
};

// ACTION CREATORS
export const setTestsActionCreator = (tests) => ({ type: SET_TESTS, tests });
export const setCurrentTestActionCreator = (test) => ({ type: SET_CURRENT_TEST, test });

export const createTestActionCreator = (test) => ({ type: CREATE_TEST, test });
export const createQuestionActionCreator = (question) => ({ type: CREATE_QUESTION, question });
export const createChoiceActionCreator = (choice, test) => ({ type: CREATE_CHOICE, choice, test });

export const deleteTestActionCreator = (testId) => ({type: DELETE_TEST, testId});

export const selectTestActionCreator = (testId) => ({type: SELECT_TEST, testId});
export const clearSelectedTests = () => ({type: CLEAR_SELECTED_TESTS});
export const removeSelectedTest = (testId) => ({type: REMOVE_SELECTED_TEST, testId});

// THUNKS
export const getTests = () => dispatch => {
    testsAPI.getTests()
        .then(response => {
            dispatch(stopLoadingActionCreator());
            dispatch(setTestsActionCreator(response.data));
        })
        .catch(error => {
            if (error.status === 401) {
                errorToast("Ваша сессия истекла");
            }
        })
};
export const getTest = (id) => dispatch => {
    testsAPI.getTest(id)
    .then(response => {
        dispatch(stopLoadingActionCreator());
        dispatch(setCurrentTestActionCreator(response.data));
    })
};
export const getTestCandidate = (id) => dispatch => {
    testsAPI.getTestCandidate(id)
        .then(response => {
            dispatch(stopLoadingActionCreator());
            dispatch(setCurrentTestActionCreator(response.data));
        })
        .catch(error => {
            errorToast("Что-то пошло не так. Попробуйте обратиться в службу поддержки");
        })
};

export const createTest = (formData, test, navigate) => dispatch => { 
    testsAPI.createTest(formData)
        .then(response => {
            if (response.status === 201) {
                successToast("Тест успешно создан");
                navigate("/tests");
                dispatch(createTestActionCreator(response.data));
                test.questions.forEach(question => {
                    dispatch(createQuestion("multiple", question.text, question.text, question.text, response.data.id, question));
                })
            }
            return response; // RETURNING PROMISE SO THAT WE CAN THEN CREATE A QUESTION
        })
};
export const createQuestion = (type, name, text, description, test, question) => dispatch => {
    testsAPI.createQuestion(type, name, text, description, test)
        .then(response => {
            if (response.status === 201) {
                dispatch(createQuestionActionCreator(response.data));
                question.choices.forEach(choice => {
                    dispatch(createChoice(choice.text, choice.is_correct, response.data.id));
                });
            }
            return response;
        })
};
export const createChoice = (text, is_correct, question) => dispatch => {
    testsAPI.createChoice(text, is_correct, question);
};

export const updateTest = (formData, test, navigate, currentTest) => dispatch => {
    testsAPI.updateTest(formData, test.id)
    .then(response => {
        if(response.status === 200) {
            successToast("Тест обновлен");
            navigate("/tests");
            test.questions.forEach((question, index) => {
                if(currentTest.questions.some(q => q.id === question?.id )) {
                    dispatch(updateQuestion(question.text, question.text, question.text, test.id, question.id, question, currentTest.questions[index].choices));
                } else {
                    dispatch(createQuestion("multiple", question.text, question.text, question.text, response.data.id, question));
                }
            })
        }
    })
};
export const updateQuestion = (name, text, description, test, id, question, currentTestChoices) => dispatch => {
    testsAPI.updateQuestion(name, text, description, test, id)
    .then(response => {
        if(response.status === 200) {
            question.choices.forEach(choice => {
                if(currentTestChoices.some(ch => ch.id === choice?.id)) {
                    dispatch(updateChoice(choice.text, choice.is_correct, choice.id));
                } else {
                    dispatch(createChoice(choice.text, choice.is_correct, question.id));
                }
            })
        }
    })
};
export const updateChoice = (text, is_correct, id) => dispatch => {
    testsAPI.updateChoice(text, is_correct, id);
};

export const deleteTest = (id, navigate) => dispatch => {
    testsAPI.deleteTest(id)
    .then(response => {
        if(response.status === 204) {
            successToast("Тест успешно удален");
            navigate("/tests");
            dispatch(deleteTestActionCreator(id));
        }
    });
};