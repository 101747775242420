import { useNavigate } from "react-router-dom";
import DropDown from "../../components/functional/DropDown/DropDown";
import style from "./Registration.module.css";
import { registrationValidate } from "../../utils/formValidators/registrationValidate";
import { Field, Form } from "react-final-form";
import { IMaskInput } from 'react-imask';
import roles from "../../utils/getRoles/getRoles";
import Select from 'react-select';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "../../redux/companiesReducer";
import { selectCompanies } from "../../utils/selectCompanies/selectCompanies";
import { getUserGroups } from "../../redux/userGroupsReducer";
import { selectUserGroups } from "../../utils/selectUserGroups/selectUserGroups";
import { errorToast } from "../../utils/toasts/toasts";
import ButtonNew from "../../components/UI/ButtonNew/ButtonNew";

// REGISTRATION FORM
const RegistrationForm = ({ register, role, setSecondStage, secondStage, companies, userGroups }) => {

    // USE STATE
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedUserGroup, setSelectedUserGroup] = useState(null);
    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [userGroupsOptions, setUserGroupsOptions] = useState([]);
    
    // USE DISPATCH
    const dispatch = useDispatch();

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE EFFECT
    useEffect(() => {
        if (secondStage && role === "super") {
            dispatch(getCompanies());
        }

        if (secondStage && role !== "super") {
            dispatch(getCompanies());
            dispatch(getUserGroups());
        }
    }, [secondStage, role]);
    useEffect(() => {
        if (companies) {
            setCompaniesOptions(selectCompanies(companies));
        }

        if (userGroups) {
            setUserGroupsOptions(selectUserGroups(userGroups));
        }
    }, [companies, userGroups]);
    useEffect(() => {
        if(role !== "super" && companiesOptions?.[0]?.label) {
            setSelectedCompany(companiesOptions?.[0]?.value);
        };
    }, [companiesOptions]);

    // CALLBACK FUNCTIONS
    const moveToSecondStage = function () {

        setSecondStage(true);
    };

    // HANDLE SUBMIT
    const onSubmit = function (values) {

        // GETTING DATA FROM FORM
        const { name, email, password, phone } = values;

        if(!secondStage) {
            if(!name || !email || !password || !phone) {
                errorToast("Some field is empty!");
                return;
            }
            moveToSecondStage();
            return;
        }

        const role = selectedRole?.value || createRoles[0]?.value || "user";
        register(name, email, password, 
            phone.replace(/\s|\(|\)/g, ''), 
            role, navigate, 
            selectedCompany.value || companiesOptions[0].value, 
            selectedUserGroup?.value ? [selectedUserGroup?.value] : []);
    };

    const onRoleSelect = (e) => {
        setSelectedRole(roles.find(role => role.value === e.value));
    };
    const onCompanySelect = (e) => {
        setSelectedCompany(companiesOptions.find(company => company.value === e.value));
    };
    const onUserGroupSelect = (e) => {
        setSelectedUserGroup(userGroupsOptions.find(userGroup => userGroup.value === e.value));
    };

    // GIVING PERMISSIONS FOR CREATING USERS WITH DIFFERENT ROLES
    const createRoles = role === "super"
        ? [roles[0]]
        : (role === "admin"
            ? roles.slice(1)
            : [roles.at(-1)]);


    // LAYOUT
    return (
        <Form
            onSubmit={onSubmit}
            validate={registrationValidate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    {!secondStage && (
                        <div>
                            <div className="flex__column input__label">
                                <label className="text__label">Имя</label>
                                <Field
                                    name="name"
                                    render={({ input }) => (
                                        <div className={`flex ${style.input_validation}`}>
                                            <input
                                                {...input}
                                                type="text"
                                                className="input input_medium"
                                            />
                                        </div>
                                    )}
                                />
                                <div style={{ color: 'red' }}>
                                    <Field name="name" subscription={{ touched: true, error: true }}>
                                        {({ meta }) => (meta.touched && meta.error ? <span>{meta.error}</span> : null)}
                                    </Field>
                                </div>
                            </div>
                            <div className="flex__column input__label">
                                <label className="text__label">Номер телефона</label>
                                <Field
                                    name="phone"
                                    render={({ input }) => (
                                        <div className={`flex ${style.input_validation}`}>
                                            <IMaskInput
                                                {...input}
                                                mask="+998 (00) 000 00 00"
                                                placeholder="+998"
                                                className="input input_medium"
                                            />
                                        </div>
                                    )}
                                />
                                <div style={{ color: 'red' }}>
                                    <Field name="phone" subscription={{ touched: true, error: true }}>
                                        {({ meta }) => (meta.touched && meta.error ? <span>{meta.error}</span> : null)}
                                    </Field>
                                </div>
                            </div>
                            <div className="flex__column input__label">
                                <label className="text__label">Электронная почта</label>
                                <Field
                                    name="email"
                                    render={({ input }) => (
                                        <div className={`flex ${style.input_validation}`}>
                                            <input
                                                {...input}
                                                type="email"
                                                className="input input_medium"
                                                placeholder="example@mail.ru"
                                            />
                                            <div className="validation__error" style={{ color: 'red' }}>
                                                <Field name="email" subscription={{ touched: true, error: true }}>
                                                    {({ meta }) => (meta.touched && meta.error ? <span className={style.error}></span> : null)}
                                                </Field>
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className={`flex__column input__label ${style.input__label_margin}`}>
                                <label className="text__label">Пароль</label>
                                <Field
                                    name="password"
                                    render={({ input }) => (
                                        <div className={`flex ${style.input_validation}`}>
                                            <input
                                                {...input}
                                                type="password"
                                                className="input input_medium"
                                                placeholder="минимум 8 символов"
                                            />
                                            <div className="validation__error" style={{ color: 'red' }}>
                                                <Field name="password" subscription={{ touched: true, error: true }}>
                                                    {({ meta }) => (meta.touched && meta.error ? <i className={`icon-info-circle ${style.error}`} /> : null)}
                                                </Field>
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    )}
                    {secondStage && role === "super" && (
                        <div>
                            <div className={`flex__column input__label ${style.secondStageCompany}`}>
                                <label className="text__label">Компания</label>
                                <Select
                                    value={selectedCompany}
                                    onChange={onCompanySelect}
                                    options={companiesOptions}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderColor: "#E8E8E8",
                                            borderRadius: "10px",
                                            backgroundColor: "#FAFDFF",
                                            boxShadow: state.isFocused ? null : null,
                                            width: "464px",
                                            height: 50,
                                            "&:hover": {
                                                borderColor: '#E8E8E8'
                                            },
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected && "#FF971A",
                                            "&:hover": {
                                                backgroundColor: '#ffdbaf'
                                            },
                                        }),
                                        menu: (provided, state) => ({
                                            ...provided,
                                            width: "464px",
                                        }),
                                    }}
                                />
                            </div>
                            <div className={`flex__column input__label ${style.secondStageRole}`}>
                                <label className="text__label">Роль</label>
                                <Select
                                    value={selectedRole || createRoles[0]}
                                    onChange={onRoleSelect}
                                    options={createRoles}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderColor: "#E8E8E8",
                                            borderRadius: "10px",
                                            backgroundColor: "#FAFDFF",
                                            boxShadow: state.isFocused ? null : null,
                                            width: "464px",
                                            height: 50,
                                            "&:hover": {
                                                borderColor: '#E8E8E8'
                                            },
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected && "#FF971A",
                                            "&:hover": {
                                                backgroundColor: '#ffdbaf'
                                            },
                                        }),
                                        menu: (provided, state) => ({
                                            ...provided,
                                            width: "464px",
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {
                        secondStage && role !== "super" && (
                            <div>
                                <div className={`flex__column input__label ${style.secondStageCompany}`}>
                                    <label className="text__label">Компания</label>
                                    <Field
                                        name="company"
                                        render={({ input }) => (
                                            <div className={`flex ${style.input_validation}`}>
                                                <input
                                                    {...input}
                                                    type="text"
                                                    value={companiesOptions?.[0]?.label}
                                                    className="input input_medium"
                                                    disabled
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className={`flex__column input__label ${style.secondStageRole}`}>
                                    <label className="text__label">Роль</label>
                                    <Select
                                        value={selectedRole || createRoles[0]}
                                        onChange={onRoleSelect}
                                        options={createRoles}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderColor: "#E8E8E8",
                                                borderRadius: "10px",
                                                backgroundColor: "#FAFDFF",
                                                boxShadow: state.isFocused ? null : null,
                                                width: "464px",
                                                height: 50,
                                                "&:hover": {
                                                    borderColor: '#E8E8E8'
                                                },
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isSelected && "#FF971A",
                                                "&:hover": {
                                                    backgroundColor: '#ffdbaf'
                                                },
                                            }),
                                            menu: (provided, state) => ({
                                                ...provided,
                                                width: "464px",
                                            }),
                                        }}
                                    />
                                </div>
                                <div className={`flex__column input__label ${style.secondStageUserGroup}`}>
                                    <label className="text__label">Департамент</label>
                                    <Select
                                        value={selectedUserGroup || userGroupsOptions[0]}
                                        onChange={onUserGroupSelect}
                                        options={userGroupsOptions}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderColor: "#E8E8E8",
                                                borderRadius: "10px",
                                                backgroundColor: "#FAFDFF",
                                                boxShadow: state.isFocused ? null : null,
                                                width: "464px",
                                                height: 50,
                                                "&:hover": {
                                                    borderColor: '#E8E8E8'
                                                },
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isSelected && "#FF971A",
                                                "&:hover": {
                                                    backgroundColor: '#ffdbaf'
                                                },
                                            }),
                                            menu: (provided, state) => ({
                                                ...provided,
                                                width: "464px",
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {!secondStage && <ButtonNew text="Продолжить" className="button_gradient" type="submit" />}
                    {secondStage && <ButtonNew text="Создать" className="button_gradient" type="submit" />}
                </form>
            )}
        />
    )
}

// LAYOUT
const Registration = ({ register, role, isAuth }) => {

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE SELECTOR
    const {companies: companiesState, auth: authState, userGroups: userGroupsState} = useSelector(state => state);
    // const companiesState = useSelector(state => state.companies);
    // const authState = useSelector(state => state.auth);
    // const userGroupsState = useSelector(state => state.userGroups);

    // USE STATE
    const [secondStage, setSecondStage] = useState(false);

    // CHECK IF THE USER IS AUTHENTICATED AND IF IT IS NOT A SIMPLE USER
    useEffect(() => {
        if (!isAuth || role === "user") {
            navigate("/login");
        }
    }, [role, isAuth, navigate]);


    return (
        <div className={`flex ${style.registration}`}>
            <div className={style.registrationDescription}>
                <span className={style.registrationDescriptionDocument}></span>
            </div>
            <div className={style.registrationForm}>
                <div className={style.form}>
                    <div className={`flex ${style.settings}`}>
                        <DropDown text="Ру" />
                    </div>
                    <div className={`flex ${style.formStages}`}>
                        <div className={`${style.formStagesStage} ${style.formStagesStageActive}`}></div>
                        <div className={`${style.formStagesStick} ${secondStage ? style.formStagesStickActive : ""}`}></div>
                        <div className={`${style.formStagesStage} ${secondStage ? style.formStagesStageActive : ""}`}></div>
                    </div>
                    <h2 className={style.headingMargin}>Создание пользователя</h2>
                    <p className={style.textLight}>Введите данные</p>
                    <RegistrationForm
                        register={register}
                        role={role}
                        secondStage={secondStage}
                        setSecondStage={setSecondStage}
                        companies={companiesState.companies}
                        companyId={authState.companyId}
                        userGroups={userGroupsState.userGroups}
                    />
                </div>
            </div>
        </div>
    );
};

// EXPORTING REGISTRATION PAGE
export default Registration;

