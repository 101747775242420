import { useDispatch } from "react-redux";
import style from "./TestCheckbox.module.css";
import { setAnswerActionCreator } from "../../../redux/answersReducer";

// TEST CHECKBOX COMPONENT
const TestCheckbox = ({option, index, questionNumber, question}) => {

    // USE DISPATCH
    const dispatch = useDispatch();

    // ADD ANSWER TO THE STATE
    const addAnswer = function(event) {
        dispatch(setAnswerActionCreator(question, option.id, questionNumber));
    }

    // LAYOUT
    return (
        <label htmlFor={`option-${questionNumber}-${index}`} className={`flex ${style.checkbox}`} onClick={addAnswer}>
            <input 
                className={style.hiddenCheckbox} 
                id={`option-${questionNumber}-${index}`} 
                type="checkbox" 
                onClick={(e) => e.stopPropagation()} 
            />
            <div className={style.checkboxInput}></div>
            <p className={style.checkboxText}>{option?.text}</p>
        </label>
    );
};

// EXPORT 
export default TestCheckbox;