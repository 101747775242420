import style from "./TestsCreate.module.css";
import BreadCrumbs from "../../../components/functional/BreadCrumbs/BreadCrumbs";
import { useState } from "react";
import NavigationBar from "../../../components/UI/NavigationBar/NavigationBar";
import CRUDHeader from "../../../components/UI/CRUDHeader/CRUDHeader";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import InfoBlock from "../../../components/UI/InfoBlock/InfoBlock";
import FileDrop from "../../../components/functional/FileDrop/FileDrop";
import { setImage } from "../../../utils/imageUpload/setImage";
import Input from "../../../components/UI/Input/Input";
import SelectComponent from "../../../components/functional/SelectComponent/SelectComponent";
import testTypes from "../../../utils/getTestTypes/getTestTypes";
import QuestionBlock from "../QuestionBlock";
import { createTest } from "../../../redux/testsReducer";
import { timeToMinutes } from "../../../utils/timeToMinutes/timeToMinutes";
import { errorToast } from "../../../utils/toasts/toasts";
import ButtonNew from "../../../components/UI/ButtonNew/ButtonNew";

// PAGE FOR CREATING A TEST
const TestsCreate = ({ isFromAssignTestsPage }) => {

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE DISPATCH
    const dispatch = useDispatch();

    // USE SELECTOR
    const authState = useSelector(state => state.auth);

    // USE STATE
    const [test, setTest] = useState({
        questions: [
            {
                text: "",
                index: 0,
                choices: [
                    {
                        text: "",
                        is_correct: false,
                        index: 0,
                    },
                    {
                        text: "",
                        is_correct: false,
                        index: 1,
                    },
                    {
                        text: "",
                        is_correct: false,
                        index: 2,
                    },
                    {
                        text: "",
                        is_correct: false,
                        index: 3,
                    },
                ],
            },
        ],
    });

    // NUMBER OF QUESTIONS NOW
    const [numberOfQuestions, setNumberOfQuestions] = useState(1);

    // HANDLING IMAGE
    const handleCoverImage = (file) => {
        setTest({
            ...test,
            cover_image: file,
        });
        setImage(file, "imagePreview");
        if (!isFromAssignTestsPage) {
            setImage(file, "crumbsImage");
        }
        setImage(file, "crudImage");
    };

    // ON COVER IMAGE CHANGE
    const onCoverImageChange = (event) => {
        const file = event?.target?.files[0];
        handleCoverImage(file);
    };

    // ON DROP FILES EVENT
    const onDropFiles = (files) => {
        const file = files[0];
        handleCoverImage(file);
    };

    // TEST MODIFICATION FUNCTIONS
    const onQuestionChange = (index) => (event) => {
        setTest({
            ...test,
            questions: test.questions.map((question, i) => {
                if (index === question.index) {
                    return ({ ...question, text: event.target.value });
                }
                return question;
            })
        });
    };
    const onQuestionDelete = index => {
        setNumberOfQuestions(prev => prev - 1);
        const updatedQuestions = test.questions
            .filter(question => question.index !== index)
            .map((question, newIndex) => ({ ...question, index: newIndex }));
        setTest({
            ...test,
            questions: updatedQuestions,
        });
    };
    const onOptionChange = (questionIndex, choiceIndex) => (event) => {
        setTest({
            ...test,
            questions: test.questions.map((question, i) => {
                if (questionIndex === question.index) {
                    return ({
                        ...question, choices: question.choices.map((choice, choiceI) => {
                            if (choice.index === choiceIndex) {
                                return { ...choice, text: event.target.value };
                            }
                            return choice;
                        })
                    })
                }
                return question;
            })
        });
    };
    const onOptionDelete = (questionIndex, choiceIndex) => () => {
        setTest({
            ...test,
            questions: test.questions.map(question => {
                if (question.index === questionIndex) {
                    return ({
                        ...question,
                        choices: question.choices
                            .filter(choice => choice.index !== choiceIndex)
                            .map((choice, newIndex) => ({ ...choice, index: newIndex }))
                    })
                }
                return question;
            })
        });
    };
    const onAddOption = questionIndex => () => {
        setTest({
            ...test,
            questions: test.questions.map(question => {
                if (question.index === questionIndex) {
                    return ({
                        ...question,
                        choices: [
                            ...question.choices,
                            {
                                text: "",
                                is_correct: false,
                                index: question.choices.length,
                            }
                        ]
                    })
                }
                return question;
            })
        });
    };
    const onAddButtonClick = () => {
        setNumberOfQuestions(previous => previous + 1);
        setTest({
            ...test,
            questions: [
                ...test.questions,
                {
                    text: "",
                    index: numberOfQuestions,
                    choices: [
                        {
                            text: "",
                            is_correct: false,
                            index: 0,
                        },
                        {
                            text: "",
                            is_correct: false,
                            index: 1,
                        },
                        {
                            text: "",
                            is_correct: false,
                            index: 2,
                        },
                        {
                            text: "",
                            is_correct: false,
                            index: 3,
                        },
                    ],
                },
            ],
        });
    };
    const onCorrectCheckboxChecked = (questionIndex, choiceIndex) => () => {
        setTest({
            ...test,
            questions: test.questions.map((question, i) => {
                if (questionIndex === question.index) {
                    return ({
                        ...question, choices: question.choices.map((choice, choiceI) => {
                            if (choice.index === choiceIndex) {
                                return { ...choice, is_correct: !choice.is_correct };
                            }
                            return choice;
                        })
                    })
                }
                return question;
            })
        });
    };

    const links = [
        {
            path: "/tests",
            icon: "icon-document-normal",
            current: false,
            text: "Центр тестирования",
        },
        {
            image: test?.cover_image,
            current: false,
            text: test?.name || "Название теста",
        },
        {
            path: "/tests/create",
            icon: "icon-add-square",
            current: true,
            text: "Создание теста",
        },
    ];

    // LINKS ARRAY FOR NAVIGATION BAR
    const linksNavigation = [
        {
            icon: "icon-document-text",
            text: "Назначение",
            current: false,
            path: "/tests/assign",
        },
        {
            icon: "icon-document-normal",
            text: "Тесты",
            current: true,
            path: "/tests",
        },
        {
            icon: "icon-clipboard-tick",
            text: "Результаты",
            current: false,
            path: "/results",
        }
    ];

    // BUTTONS FOR HEADER
    const buttonsHeader = [
        {
            text: "Назад",
            className: "button_transparent",
            onClick: () => {
                navigate("/tests");
            },
        },
        {
            text: "Сохранить",
            className: "button_gradient",
            onClick: () => {
                const formData = new FormData();

                // VALIDATING EMPTY FIELDS
                if (!test?.name || !test?.type) {
                    errorToast("Введите все данные");
                    return;
                }

                // ITERATING OVER QUESTIONS AND CHOICES TO FIND IF THERE ARE ANY EMPTY
                let isAnyQuestionEmpty = false;
                test?.questions.forEach(question => {
                    if (question.text === "") {
                        errorToast("Один или несколько из вопросов не заполнены");
                        isAnyQuestionEmpty = true;
                    }

                    // CHECKING IF AT LEAST ONE CHOICE IS SET TO CORRECT
                    if (!question.choices.some(choice => choice.is_correct)) {
                        errorToast("В одном или нескольких вопросах нет правильного ответа");
                        isAnyQuestionEmpty = true;
                    }

                    question?.choices.forEach(choice => {
                        if (choice.text === "") {
                            errorToast("Один или несколько из вопросов не заполнены");
                            isAnyQuestionEmpty = true;
                        }
                    })
                });
                if (isAnyQuestionEmpty) {
                    return;
                }

                formData.append("name", test.name);
                formData.append("type", test.type);
                formData.append("author", authState.profileData.id);
                if (test?.cover_image) {
                    formData.append("cover_image", test.cover_image);
                }
                if (test?.time_allocated) {
                    formData.append("time_allocated", timeToMinutes(test.time_allocated));
                }
                formData.append("company", authState.companyId || 1);
                dispatch(createTest(formData, test, navigate));
            },
        },
    ];

    // ELEMENTS FOR CREATING TEST
    const elements = [
        {
            label: "Обложка",
            isFullWidth: true,
            item: (
                <FileDrop
                    onChange={onCoverImageChange}
                    onDropFiles={onDropFiles}
                    image={test?.cover_image}
                />
            )
        },
        {
            label: "Название теста",
            item: (
                <Input
                    type="text"
                    id="testName"
                    onChange={(event) => {
                        setTest({
                            ...test,
                            name: event.target.value,
                        });
                    }}
                    placeholder="Название теста"
                />
            )
        },
        {
            label: "Категория",
            item: (
                <SelectComponent
                    value={testTypes.find(type => type.value === test?.type)}
                    onChange={(event) => {
                        setTest({
                            ...test,
                            type: event.value,
                        })
                    }}
                    options={testTypes}
                />
            ),
        },
        {
            label: "Длительность",
            item: (
                <Input
                    onChange={(event) => {
                        setTest({
                            ...test,
                            time_allocated: event.target.value,
                        });
                    }}
                    placeholder="00:00:00"
                    mask="00:00:00"
                />
            ),
        },
    ];

    // LAYOUT
    if (!isFromAssignTestsPage) {
        return (
            <div className={`flex__column ${style.create}`}>
                <BreadCrumbs links={links} />
                <div className={`flex ${style.createMain}`}>
                    <NavigationBar links={linksNavigation} buttons={[]} />
                    <div className={`flex__column ${style.createMainRightside}`}>
                        <CRUDHeader
                            image={test?.cover_image}
                            name={test?.name || "Название"}
                            category={test?.type || "Категория"}
                            buttons={buttonsHeader}
                        />
                        <InfoBlock heading="Данные о тесте" isEditMode={true} values={elements} />
                        <div className={`flex__column ${style.viewMainDataQuestions}`}>
                            {
                                test?.questions.map((question, index) => {
                                    return (
                                        <QuestionBlock
                                            question={question}
                                            key={question.index}
                                            questionNumber={question.index + 1}
                                            index={question.index}
                                            isEditMode={true}
                                            onQuestionChange={onQuestionChange}
                                            onQuestionDelete={onQuestionDelete}
                                            onOptionChange={onOptionChange}
                                            numberOfQuestions={numberOfQuestions}
                                            onCorrectCheckboxChecked={onCorrectCheckboxChecked}
                                            onOptionDelete={onOptionDelete}
                                            onAddOption={onAddOption}
                                        />
                                    );
                                })
                            }
                            <div className={style.viewMainDataQuestionsAddButton}>
                                <ButtonNew
                                    text="Добавить вопрос"
                                    className="button_gradient"
                                    icon="icon-add-square"
                                    onClick={onAddButtonClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={`flex__column ${style.createMainRightside}`}>
                <CRUDHeader
                    image={test?.cover_image}
                    name={test?.name || "Название"}
                    category={test?.type || "Категория"}
                    buttons={buttonsHeader}
                />
                <InfoBlock heading="Данные о тесте" isEditMode={true} values={elements} />
                <div className={`flex__column ${style.viewMainDataQuestions}`}>
                    {
                        test?.questions.map((question, index) => {
                            return (
                                <QuestionBlock
                                    question={question}
                                    key={question.index}
                                    questionNumber={question.index + 1}
                                    index={question.index}
                                    isEditMode={true}
                                    onQuestionChange={onQuestionChange}
                                    onQuestionDelete={onQuestionDelete}
                                    onOptionChange={onOptionChange}
                                    numberOfQuestions={numberOfQuestions}
                                    onCorrectCheckboxChecked={onCorrectCheckboxChecked}
                                    onOptionDelete={onOptionDelete}
                                    onAddOption={onAddOption}
                                />
                            );
                        })
                    }
                    <div className={style.viewMainDataQuestionsAddButton}>
                        <ButtonNew
                            text="Добавить вопрос"
                            className="button_gradient"
                            icon="icon-add-square"
                            onClick={onAddButtonClick}
                        />
                    </div>
                </div>
            </div>
        )
    }
};

// EXPORT
export default TestsCreate;