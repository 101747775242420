import style from "./Input.module.css";
import { IMaskInput } from 'react-imask';

// INPUT COMPONENT
const Input = ({ type, id, onChange, placeholder, mask, value, className }) => {

    // LAYOUT
    return (
        <>
            {
                !mask
                    ? <input
                        value={value}
                        className={`${style.input} ${className}`}
                        type={type}
                        id={id}
                        onChange={onChange}
                        placeholder={placeholder}
                    />
                    : <IMaskInput
                        value={value}
                        className={style.input}
                        onChange={onChange}
                        placeholder={placeholder}
                        mask={mask}
                    />
            }
        </>
    );
};

// EXPORT
export default Input;