import style from "./InfoBlock.module.css";

// REUSABLE COMPONENT FOR ALL VIEW, EDIT AND CREATE PAGES
const InfoBlock = ({heading, isEditMode, values}) => {

    // LAYOUT
    return (
        <div className={`flex__column ${style.block}`}>
            <h3>{heading}</h3>
            <div className={`flex ${style.blockList}`}>
                {
                    !isEditMode && values.map((value, index) => {
                        return (
                            <div className={`flex__column ${style.blockListItem}`} key={index}>
                                <p className={style.blockListItemLabel}>{value.label}</p>
                                <p className={style.blockListItemValue}>{value.value}</p>
                            </div>
                        )
                    })
                }
                {
                    isEditMode && values.map((element, index) => {
                        return (
                            <div 
                                className={`flex__column ${style.blockListElement} ${element.isFullWidth 
                                    ? style.blockListInputFull 
                                    : style.blockListInput}`} 
                                key={index}
                            >
                                <p className={style.blockListItemText}>{element.label}</p>
                                {element.item}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

// EXPORT 
export default InfoBlock;