export const convertDateFromISO = function (inputDate) {
    // Create a Date object from the input string
    const date = new Date(inputDate);

    // Extract day, month, and year components
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed
    const year = date.getUTCFullYear();

    // Pad the day and month with leading zeros if necessary
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;

    // Create the formatted date string in the desired format
    const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

    return formattedDate;
};