import ButtonNew from "../../../UI/ButtonNew/ButtonNew";
import Input from "../../../UI/Input/Input";
import style from "./Candidate.module.css";

// CANDIDATE COMPONENT
const Candidate = ({ number, onEmailChange, onNameChange, onCandidateRemove, onCandidateAdd, emailValue, nameValue, isLast, index }) => {

    // LAYOUT
    return (
        <div className={`flex__column ${style.candidate}`}>
            <div className={`flex ${style.candidateNumber}`}>
                <p className={style.candidateNumberText}>{number} Кандидат</p>
                {
                    (index === 0 && isLast) 
                    ? null
                    : <i className={`icon-trash ${style.candidateNumberIcon}`} onClick={onCandidateRemove} />
                }
            </div>
            <div className={`flex__column ${style.candidateInputs}`}>
                <Input
                    type="email"
                    id={`email-${number}`}
                    onChange={onEmailChange}
                    placeholder="abc@abc.com"
                    value={emailValue}
                />
                <Input
                    type="name"
                    id={`name-${number}`}
                    onChange={onNameChange}
                    placeholder="Имя"
                    value={nameValue}
                />
            </div>
            {/* <div className={`flex__column ${style.candidateButtons}`}>
                {
                    isLast &&
                    <ButtonNew
                        className="button_gradient_small"
                        icon="icon-add-circle"
                        onClick={onCandidateAdd}
                    />
                }
            </div> */}
        </div>
    );
};

// EXPORT
export default Candidate;