import axios from "axios";

// CREATING AXIOS INSTANCE
const instance = axios.create({
  baseURL: 'http://127.0.0.1:8000/',
  // baseURL: "https://api01.jobox.uz/"
});

// Function to get the JWT token from storage (e.g., localStorage)
const getToken = () => localStorage.getItem('token'); 

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;