import { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/functional/BreadCrumbs/BreadCrumbs";
import style from "./CompaniesCreate.module.css";
import NavigationBar from "../../../components/UI/NavigationBar/NavigationBar";
import CRUDHeader from "../../../components/UI/CRUDHeader/CRUDHeader";
import { useNavigate } from "react-router";
import InfoBlock from "../../../components/UI/InfoBlock/InfoBlock";
import SelectComponent from "../../../components/functional/SelectComponent/SelectComponent";
import { useDispatch, useSelector } from "react-redux";
import { startLoadingActionCreator } from "../../../redux/preloaderReducer";
import { getUsers } from "../../../redux/usersReducer";
import Preloader from "../../../components/UI/Preloader/Preloader";
import FileDrop from "../../../components/functional/FileDrop/FileDrop";
import { setImage } from "../../../utils/imageUpload/setImage";
import Input from "../../../components/UI/Input/Input";
import { errorToast } from "../../../utils/toasts/toasts";
import { createCompany } from "../../../redux/companiesReducer";

// COMPANY CREATE PAGE
const CompaniesCreate = () => {

    // USE SELECTOR
    const {isLoading} = useSelector(state => state.preloader);
    const {users} = useSelector(state => state.users);

    // USE DISPATCH
    const dispatch = useDispatch();

    // USE STATE
    const [company, setCompany] = useState({
        name: "",
        adminId: null,
        email: "",
        logo: null,
    });
    const [usersSelect, setUsersSelect] = useState([]);

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE EFFECT
    useEffect(() => {
        dispatch(startLoadingActionCreator());
        dispatch(getUsers());
    }, [dispatch]);
    useEffect(() => {
        if(users.length) {
            setUsersSelect(users.map(user => ({
                label: user.name,
                value: {id: user.id, email: user.email},
            })));
        }
    }, [users?.length]);

    const links = [
        {
            path: "/companies",
            icon: "icon-buildings-2",
            current: false,
            text: "Компании",
        },
        {
            image: company?.logo,
            current: false,
            text: company?.name || "Название компании",
        },
        {
            path: "/companies/create",
            icon: "icon-add-square",
            current: true,
            text: "Создание компании",
        },
    ];

    // LINKS ARRAY FOR NAVIGATION BAR
    const linksNavigation = [
        {
            image: company?.logo,
            text: "Компания",
            current: true,
            path: "/companies",
        },
    ];

    // HANDLING IMAGE
    const handleLogo = (file) => {
        setCompany({
            ...company,
            logo: file,
        });
        setImage(file, "imagePreview");
        setImage(file, "crumbsImage");
        setImage(file, "crudImage");
    };

    // ON COVER IMAGE CHANGE
    const onLogoChange = (event) => {
        const file = event?.target?.files[0];
        handleLogo(file);
    };
    // ON DROP FILES EVENT
    const onDropFiles = (files) => {
        const file = files[0];
        handleLogo(file);
    };

    // BUTTONS FOR HEADER
    const buttonsHeader = [
        {
            text: "Назад",
            className: "button_transparent",
            onClick: () => {
                navigate("/companies");
            },
        },
        {
            text: "Сохранить",
            className: "button_gradient",
            onClick: () => {
                const formData = new FormData();
                if(!company?.adminId || !company?.name) {
                    errorToast("Вы не ввели некоторые данные");
                    return;
                }
                // LOGIC TO GET DATE A YEAR LATER FROM NOW
                const currentDate = new Date();
                const oneYearLater = new Date(currentDate);
                oneYearLater.setFullYear(currentDate.getFullYear() + 1);
                const formattedDate = oneYearLater.toISOString();
                formData.append("admin", company.adminId);
                formData.append("email", company.email);
                formData.append("name", company.name);
                formData.append("active_till", formattedDate);
                if(company?.logo) {
                    formData.append("logo", company.logo);
                }
                dispatch(createCompany(formData))
                    .then(response => {
                        navigate("/companies");
                    })
            },
        },
    ];

    // ELEMENTS FOR CREATING TEST
    const elements = [
        {
            label: "Логотип",
            isFullWidth: true,
            item: (
                <FileDrop
                    onChange={onLogoChange}
                    onDropFiles={onDropFiles}
                    image={company?.logo}
                />
            )
        },
        {
            label: "Название компании",
            item: (
                <Input
                    value={company?.name}
                    type="text"
                    id="companyName"
                    onChange={(event) => {
                        setCompany({
                            ...company,
                            name: event.target.value,
                        });
                    }}
                    placeholder="Название компании"
                />
            )
        },
        {
            label: "Администратор",
            item: (
                <SelectComponent 
                    value={usersSelect?.find(user => user?.value?.id === company?.adminId)}
                    onChange={(event) => {
                        setCompany({
                            ...company,
                            adminId: event.value.id,
                            email: event.value.email,
                        });
                    }}
                    options={usersSelect}
                />
            )
        },
    ];

    // LOADING...
    if(isLoading) return <Preloader />;

    // LAYOUT
    return (
        <div className={`flex__column ${style.create}`}>
            <BreadCrumbs links={links} />
            <div className={`flex ${style.createMain}`}>
                <NavigationBar links={linksNavigation} buttons={[]} />
                <div className={`flex__column ${style.createMainRightside}`}>
                    <CRUDHeader
                        image={company?.logo}
                        name={company?.name || "Название"}
                        buttons={buttonsHeader}
                    />
                    <InfoBlock heading="Данные о компании" isEditMode={true} values={elements} />
                </div>
            </div>
        </div>
    );
};

// EXPORT
export default CompaniesCreate;