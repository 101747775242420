import { companiesAPI, userGroupsAPI, usersAPI } from "../api/api";
import { errorToast, successToast } from "../utils/toasts/toasts";
import { removeUserActionCreator } from "./companiesReducer";
import { stopComponentLoadingActionCreator, stopLoadingActionCreator } from "./preloaderReducer";
import { deleteUserFromUsergroupActionCreator } from "./userGroupsReducer";

// ACTION TYPES
const UPDATE_USER = "UPDATE_USER";
const SET_USERS_TOTAL = "SET_USERS_TOTAL";
const SET_USERS = "SET_USERS";
const DELETE_USER = "DELETE_USER";
const SET_CURRENT_USER = "SET_CURRENT_USER";
const SET_COMPANY = "SET_COMPANY";
const SET_USER_GROUP = "SET_USER_GROUP";
const REMOVE_USER_BY_GROUP = "REMOVE_USER_BY_GROUP";
const SET_CHOSEN_USERS = "SET_CHOSEN_USERS";
const REMOVE_CHOSEN_USERS_BY_GROUP = "REMOVE_CHOSEN_USERS_BY_GROUP";
const REMOVE_CHOSEN_USER = "REMOVE_CHOSEN_USER";
const SET_ALL_CHOSEN = "SET_ALL_CHOSEN";
const RESET_CHOSEN_USERS = "RESET_CHOSEN_USERS";

// INITIAL STATE
const initialState = {
    users: [],
    totalUsers: null,
    currentUser: null,
    company: null,
    userGroups: [],
    chosenUsers: [],
};

// USERS REDUCER
export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER:
            return {
                ...state, users: state.users.map(user => {
                    if (user.id === action.user.id) {
                        return action.user;
                    }
                    return user;
                })
            };
        case SET_USERS_TOTAL:
            return { ...state, totalUsers: action.usersTotal };
        case SET_USERS:
            const combinedUsers = [...state.users, ...action.users];

            const uniqueUsers = Array.from(
                new Set(combinedUsers.map(user => user.id))
            ).map(id => combinedUsers.find(user => user.id === id));

            return { ...state, users: uniqueUsers };
        case DELETE_USER:
            return { ...state, users: state?.users?.filter(user => user.id !== action.userId) };
        case SET_CURRENT_USER:
            return { ...state, currentUser: action.user };
        case SET_COMPANY:
            return { ...state, company: action.company };
        case SET_USER_GROUP:
            return {
                ...state, userGroups: state.userGroups.some(group => group.id === action.userGroup.id)
                    ? state.userGroups
                    : [...state.userGroups, action.userGroup]
            };
        case REMOVE_USER_BY_GROUP:
            return {
                ...state,
                users: state.users.filter(user => user.user_groups[0] !== action.groupId),
            };
        case SET_CHOSEN_USERS:
            return {
                ...state,
                chosenUsers: [...state.chosenUsers, action.user],
            };
        case REMOVE_CHOSEN_USERS_BY_GROUP:
            return {
                ...state,
                chosenUsers: state.chosenUsers.filter(user => user.user_groups[0] !== action.groupId),
            };
        case REMOVE_CHOSEN_USER:
            return {
                ...state,
                chosenUsers: state.chosenUsers.filter(user => user.id !== action.userId),
            };
        case SET_ALL_CHOSEN:
            return {
                ...state,
                chosenUsers: [...state.users],
            };
        case RESET_CHOSEN_USERS:
            return {...state, chosenUsers: [],};
        default:
            return state;
    }
};

// ACTION CREATORS
export const updateUserActionCreator = (user) => ({ type: UPDATE_USER, user });
export const setUsersTotalActionCreator = (usersTotal) => ({ type: SET_USERS_TOTAL, usersTotal });
export const setUsersActionCreator = (users) => ({ type: SET_USERS, users });
export const deleteUserActionCreator = (userId) => ({ type: DELETE_USER, userId });
export const setCurrentUserActionCreator = (user) => ({ type: SET_CURRENT_USER, user });
export const setCompanyActionCreator = (company) => ({ type: SET_COMPANY, company });
export const setUserGroupActionCreator = (userGroup) => ({ type: SET_USER_GROUP, userGroup });
export const removeUserByGroupActionCreator = (groupId) => ({type: REMOVE_USER_BY_GROUP, groupId});
export const setChosenUsersActionCreator = (user) => ({type: SET_CHOSEN_USERS, user});
export const removeChosenUsersByGroupActionCreator = (groupId) => ({type: REMOVE_CHOSEN_USERS_BY_GROUP, groupId});
export const removeChosenUserActionCreator = (userId) => ({type: REMOVE_CHOSEN_USER, userId});
export const setAllUsersChosenActionCreator = () => ({type: SET_ALL_CHOSEN});
export const resetChosenUsersActionCreator = () => ({type: RESET_CHOSEN_USERS});

// THUNKS
export const createUser = (name, user_groups, email, password, company, navigate, companyObject) => dispatch => {
    return usersAPI.createUser(name, user_groups, email, password, company)
        .then(response => {
            if(response.status === 201) {
                successToast("Сотрудник успешно создан!");
                navigate(`/companies/${companyObject.id}`, {state: {id: companyObject.id}});
            }
        })
};
export const updateUser = (formData, id) => dispatch => {
    return usersAPI.updateUser(formData, id)
        .then(response => {
            if (response.status === 200) {
                successToast("Пользователь обновлен!");
                dispatch(updateUserActionCreator(response.data));
            }
        })
        .catch(error => {
            console.log("ERROR", error);
            if(error.status === 500) {
                errorToast("Ошибка сервера");
            }
        })
};
export const getUsers = () => dispatch => {
    usersAPI.getUsers()
        .then(response => {
            dispatch(stopLoadingActionCreator());
            if (response.status === 200) {
                dispatch(setUsersTotalActionCreator(response?.data?.length));
                dispatch(setUsersActionCreator(response?.data));
            }
        })
        .catch(error => {
            return;
        })
};
export const deleteUser = (userId) => dispatch => {
    return usersAPI.deleteUser(userId)
        .then(response => {
            successToast("Пользователь удален");
            dispatch(deleteUserActionCreator(userId));
            dispatch(removeUserActionCreator(userId));
            dispatch(deleteUserFromUsergroupActionCreator(userId));
        })
};
export const getUser = (userId) => dispatch => {
    usersAPI.getUser(userId)
        .then(response => {
            dispatch(stopLoadingActionCreator());
            dispatch(setCurrentUserActionCreator(response.data));
            response.data?.company && companiesAPI.getCompany(response.data.company)
                .then(response => {
                    dispatch(setCompanyActionCreator(response.data));
                })
                .catch(error => {
                    console.log(error);
                })
            response.data.user_groups?.length && response.data.user_groups.forEach(group => {
                userGroupsAPI.getUserGroup(group)
                    .then(response => {
                        dispatch(setUserGroupActionCreator(response.data));
                    })
            })
        })
};
export const getUsersOfUsergroup = usergroupId => dispatch => {
    usersAPI.getUserOfGroup(usergroupId)
        .then(response => {
            dispatch(stopComponentLoadingActionCreator());
            dispatch(setUsersActionCreator(response.data));
        })
};
export const assignTests = (accessed_tests, userId, userName) => dispatch => {
    usersAPI.assignTests(accessed_tests, userId)
        .then(response => {
            dispatch(stopLoadingActionCreator());
            if(response.status === 200) {
                successToast(`Тесты успешно назначены сотруднику ${userName}`);
            }
        })
        .catch(error => {
            dispatch(stopLoadingActionCreator());
            errorToast(`Проблемы с назначением тестов сотруднику ${userName}`);
        })
};
export const blockUser = id => dispatch => {
    usersAPI.blockUser(id)
        .then(response => {
            if(response.status === 200) {
                successToast("Пользователь заблокирован");
                dispatch(setCurrentUserActionCreator(response.data));
            }
        })
};
export const unblockUser = id => dispatch => {
    usersAPI.unblockUser(id)
        .then(response => {
            if(response.status === 200) {
                successToast("Пользователь разблокирован");
                dispatch(setCurrentUserActionCreator(response.data));
            }
        })
};